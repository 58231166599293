import { LockOutlined, CaretRightOutlined } from '@ant-design/icons';
export const ItemFullTime: React.FC = () => {
    return (
        <div className="flex flex-col pb-2">
            <div className="flex flex-col w-full">
                <div className="flex  justify-end ">
                    <span className="xs:text-[7px] xl3:text-[10px] font-medium text-[#C1C9E1] border border-[#202531] w-[35%] h-full flex items-center justify-center">0-0.5</span>
                    <div className="relative py-1  border border-[#202531] w-[65%]">
                        <span className=" text-[#C1C9E1] whitespace-nowrap xs:text-[10px] xl3:text-sm">O 2.02</span>
                        <CaretRightOutlined className="absolute top-0 right-0 rotate-[-45deg] text-[#ACE433] xs:text-[8px] xl3:text-[10px]" />
                    </div>
                </div>
                <div className="flex  justify-end">
                    <div className="relative py-1  border border-[#202531] w-[65%]">
                        <span className=" text-[#C1C9E1] whitespace-nowrap xs:text-[10px] xl3:text-sm">E 2.02</span>
                        <CaretRightOutlined className="absolute top-0 right-0 rotate-[-45deg] text-[#ACE433] xs:text-[8px] xl3:text-[10px]" />
                    </div>
                </div>
                <div className="flex  justify-end">
                    <div className="border border-[#202531] py-1 w-[65%]">
                        <LockOutlined className="text-[#1D212D] " />
                    </div>
                </div>

            </div>
        </div>
    )
}