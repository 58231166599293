/* eslint-disable @typescript-eslint/no-unused-vars */
import axiosClient from "../axiosClient";
import axiosClient365 from "../axiosClient365";

import { paserParams } from "../../until";
import authuClient from "../authuClient";
import { ResponseSuccess } from "../axiosClient";


export const myBetRunning = async (sport: string, timeZone: string, limit: number = 20, offset: number = 0) => {
    const path = "api/user/my-bet/running"
    const params = { sport, timeZone, limit, offset }
    const result: ResponseSuccess<any> = await axiosClient365.get(path,
        // paserParams(params, undefined)
        { params: paserParams(params, undefined) }
    );
    return result;
};

export const myBetSettled = async (sport: string, timeZone: string, limit: number = 20, offset: number = 0) => {
    const path = "api/user/my-bet/settled"
    const params = { sport, timeZone, limit, offset }
    const result: ResponseSuccess<any> = await axiosClient365.get(path,
        // paserParams(params, undefined)
        { params: paserParams(params, undefined) }
    );
    return result;
};

export const myBetStatement = async (sport: string, timeZone: string, limit: number = 20, offset: number = 0, from: string, to: string) => {
    const path = "api/user/my-bet/statement"
    const params = { sport, timeZone, limit, offset, from, to }
    const result: ResponseSuccess<any> = await axiosClient365.get(path,
        // paserParams(params, undefined)
        { params: paserParams(params, undefined) }
    );
    return result;
};

export const changeUsername = async (username: string) => {
    const path = "api/user/change-username"
    const params = { username }
    const result: ResponseSuccess<any> = await axiosClient365.post(path,
        paserParams(params, undefined)
    );
    return result;
};

export const changePassword = async (oldPassword: string, newPassword: string, confirmNewPassword: string) => {
    const path = "api/user/change-password"
    const params = { oldPassword, newPassword, confirmNewPassword }
    const result: ResponseSuccess<any> = await axiosClient365.post(path,
        paserParams(params, undefined)
    );
    return result;
};

