import { useEffect, useState } from "react";
import { HeaderTabNew } from "../components/HeaderTabNew";
import { useNavigate } from "react-router-dom";

interface LayoutProps {
    children?: React.ReactNode;
}
export const Layout: React.FC<LayoutProps> = ({
    children
}) => {
    const navigate = useNavigate()
    const [chooseTabMybet, setChooseTabMybet] = useState<boolean>(false)
    const [chooseTabStatement, setChooseTabStatement] = useState<boolean>(false)
    const handleChooseTabMybet = () => {
        setChooseTabMybet(true);
        navigate('/mybet');
    };

    const handleChooseTabStatement = () => {
        setChooseTabStatement(true);
        navigate('/statement');
    };


    return (
        <div className="flex flex-col items-center w-full overflow-hidden ">
            <HeaderTabNew
                chooseTabMybet={chooseTabMybet}
                chooseTabStatement={chooseTabStatement}
                handleChooseTabStatement={handleChooseTabStatement}
                handleChooseTabMybet={handleChooseTabMybet}
            />
            {children}
        </div>
    )
}