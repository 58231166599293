import { useState } from "react";
import { down_icon, LogoWithoutText, up_icon, video } from "../../../components/imgExport"
import { ItemFirstHalf } from "./Item/ItemFirstHalf";
import { ItemFullTime } from "./Item/ItemFullTime";
import { ItemMoreBet } from "./Item/ItemMoreBet";

export const SoccerNewLiveDetail: React.FC = () => {
    const [showMoreBet, setShowMoreBet] = useState(false)
    return (
        <div className="flex flex-col">
            <div className="flex w-full">
                <div className=" border-r border-r-[#1D212D]">
                    <div className="flex flex-col gap-4  ">
                        <div className="flex flex-col items-start gap-2">
                            <div className='flex items-center gap-2'>
                                <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                                    <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                </div>
                                <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[18px] xl3:text-[18px]'>Long An FC U19</span>
                            </div>
                            <span className='text-[20px] text-[#D4B962]'>VS</span>
                            <div className='flex items-center gap-2'>
                                <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                                    <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                </div>
                                <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[18px] xl3:text-[18px]'>Long An FC U18</span>
                            </div>
                        </div>
                        <div className="w-[150px] flex flex-col items-start gap-2 border border-[#1D212D] rounded-xl p-3 mr-[54px]">
                            <div>
                                <img src={video} alt="" />
                            </div>
                            <span className="text-xs text-start text-[#E4E7F1] font-medium border-b border-b-[#1D212D] pb-2 w-full">1st Half</span>
                            <span className="text-xs text-start text-[#E4E7F1] font-medium border-b border-b-[#1D212D] pb-2 w-full">30’ 15s</span>
                            <span className="text-xs text-[#E4E7F1] font-medium ">0:0</span>
                        </div>

                    </div>
                </div>
                <div className=" flex gap-3 pl-6 pr-3 w-[76%]">
                    <div className="flex flex-col gap-3 w-full">
                        <span className="text-sm text-[#E4E7F1] font-bold uppercase border-b border-b-[#1D212D] py-2">Full time</span>
                        <div className="flex justify-around">
                            <span className="text-sm text-[#E4E7F1] font-bold uppercase py-[7px]">HDP</span>
                            <span className="text-sm text-[#E4E7F1] font-bold uppercase py-[7px]">OU</span>
                            <span className="text-sm text-[#E4E7F1] font-bold uppercase py-[7px]">1X2</span>
                            <span className="text-sm text-[#E4E7F1] font-bold uppercase py-[7px]">OE</span>
                        </div>
                        <div className="grid grid-cols-4 gap-1">
                            <ItemFullTime />
                            <ItemFullTime />
                            <ItemFullTime />
                            <ItemFullTime />
                            <ItemFullTime />
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 w-[80%]">
                        <span className="text-sm text-[#E4E7F1] font-bold uppercase border-b border-b-[#1D212D] py-2">First half</span>
                        <div className="flex justify-around">
                            <span className="text-sm text-[#E4E7F1] font-bold uppercase py-[7px]">HDP</span>
                            <span className="text-sm text-[#E4E7F1] font-bold uppercase py-[7px]">OU</span>
                            <span className="text-sm text-[#E4E7F1] font-bold uppercase py-[7px]">1X2</span>
                        </div>
                        <div className="grid grid-cols-3 gap-1">
                            <ItemFirstHalf />
                            <ItemFirstHalf />
                            <ItemFirstHalf />
                            <ItemFirstHalf />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col ">
                    <span className="text-sm text-[#E4E7F1] font-bold uppercase border-b border-b-[#1D212D] py-2 whitespace-nowrap">More bet</span>
                    <div className="flex items-center justify-center h-full">
                        <button
                            className="border-[6px] border-[#353229] rounded-[120px] h-[50px] w-[34px] flex flex-col justify-center items-center gap-1 bg-[#DBBF64] "
                            onClick={() => setShowMoreBet(!showMoreBet)}
                        >
                            <span className="text-xs font-bold text-[#131620]">+2</span>
                            <img src={`${showMoreBet ? up_icon : down_icon}`} alt="" />
                        </button>
                    </div>
                </div>
            </div>
            <div className={`transition-opacity duration-200 ease-in-out overflow-hidden ${showMoreBet ? ' opacity-100' : ' opacity-0'
                }`}>
                {
                    showMoreBet && <ItemMoreBet />
                }
            </div>


        </div>

    )
}