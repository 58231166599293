import { Modal } from "antd"
import { useEffect, useRef } from "react";
import { LogoBlack } from "../imgExport";
import './styles.css'
import { useSelector } from "react-redux";
import { selectorUser } from '../../store/slices/userSlice';
import { useReactToPrint } from "react-to-print";
// import QRCode from "react-qr-code";
import { QRCode } from 'react-qrcode-logo';
import { formatPrice } from "../../until";
import { ICON_GAMME } from "../../env";
interface Props {
    showModalBill: boolean,
    totalAmount: number,
    setShowModalBill: (value: boolean) => void,
    billCode: string
}

// function formatDate(UTC: string) {
//     let d = new Date(UTC).toLocaleDateString()
//     return d
// }

function formatDateNow() {
    let d = new Date().toLocaleDateString()
    return d
}

function formatTimeNow() {
    let date = new Date()
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let period = hours >= 12 ? 'PM' : 'AM';

    // Chuyển đổi sang định dạng 12 giờ
    hours = hours % 12 || 12;

    return hours.toString().padStart(2, "0") + ':' + minutes.toString().padStart(2, "0") + ' ' + period;
}

const ModalBillRefund: React.FC<Props> = ({
    showModalBill, totalAmount, billCode,
    setShowModalBill
}) => {

    const { machineCode } = useSelector(selectorUser);

    const contentToPrint = useRef(null);
    // const handlePrint = useReactToPrint({
    //     // documentTitle: "Print This Document",
    //     onBeforePrint: () => console.log("before printing..."),
    //     onAfterPrint: () => console.log("after printing..."),
    //     removeAfterPrint: true,
    // });

    const handlePrintRefund = function (target: any) {
        return new Promise(() => {
            console.log("forwarding print request to the main process...");
            var text: any

            text = `
    <style>
  @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    box-sizing: border-box;
  }

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Oswald', sans-serif;
    
  }
</style>

 <script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script>

 <script>
 var qrcode = document.getElementsByClassName("qrcode");
 
        var qrcode = new QRCode(document.getElementById("qrcode-parlay"), {
         text: "${billCode}",
         width: 128,
         height: 128,
     });
    </script>`


            // ${
            //                 listBill.map((item, i) => (`
            //         var qrcode-${i} = new QRCode(document.getElementById("qrcode-0"), {
            //          text: "123",
            //          width: 128,
            //          height: 128,
            //      });`

            //                 ))
            //             }
            const data = target.contentWindow.document.documentElement.outerHTML;

            const blob = new Blob([data + text], { type: "text/html" });



            const url = URL.createObjectURL(blob);
            console.log(url);


            window.electronAPI.printComponent(url, (response: any) => {
                console.log("Main: ", response);
            });

            //console.log('Main: ', data);
        });
    };

    const handleBillPrint = useReactToPrint({
        content: () => contentToPrint.current,
        documentTitle: "Bill component",
        print: handlePrintRefund,
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("sau khi in"),
    });



    useEffect(() => {
        if (showModalBill) {
            setTimeout(() => {
                handleBillPrint()
            }, 1000)
            setTimeout(() => {
                setShowModalBill(false)
            }, 5000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModalBill]);

    return (

        <Modal
            className='billRefund flex justify-center !pt-[340px]'
            title={null}
            open={showModalBill}
            onOk={() => setShowModalBill(false)}
            onCancel={() => setShowModalBill(false)}
            footer={null}
            closable={false}
        >
            {/* <div ref={contentToPrint}>Hello Again</div> */}
            {/* <div className="flex justify-end mb-3">
                <button className="bg-[#BB271A] text-white rounded px-1 font-bold" onClick={() => {
                    // handleShare();
                    handleBillPrint();
                }}>
                    Print
                </button>
            </div> */}
            <div className="break-page">
                <div>
                    <div>
                        <div className='flex justify-between items-center'>
                            <div className="w-[30%]"><img src={LogoBlack} alt="LogoBlack" /></div>
                            <div><span className='font-bold text-[18px]'>KINGBETS</span></div>
                            <div className="w-[30%]"></div>
                        </div>
                        <div className="pr-2 text-[#262626] text-right text-[12px]">
                            <span>{formatDateNow()} | {formatTimeNow()} </span>
                        </div>
                        <div className="w-full overflow-hidden">
                            <div className='whitespace-nowrap text-black leading-[5px]'>----------------------------------------------------------------------------------</div>
                        </div>
                        <div>
                            <p className='p-2 font-bold text-[#262626]'>Bill Code: {billCode}</p>
                            <p className='p-2 font-bold text-[#262626]'>Machine Code: {machineCode}</p>
                        </div>
                        <div className="w-full overflow-hidden">
                            <div className='whitespace-nowrap text-black leading-[5px]'>----------------------------------------------------------------------------------</div>
                        </div>
                    </div>
                    <div>
                        <p className="p-2 flex justify-center">WITHDRAW BILL </p>
                    </div>
                    <div>
                        <div className="pt-2">
                            <div className="flex justify-between">
                                <p className='p-2 font-bold text-[#262626]'>Total withdraw</p>
                                <p className='pr-4 p-2 font-bold text-[#262626]'>{"$" + formatPrice(totalAmount)}</p>
                            </div>
                        </div>
                        <div className="w-full overflow-hidden">
                            <div className='whitespace-nowrap text-black leading-[5px]'>----------------------------------------------------------------------------------</div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col items-center pt-8'>
                    <QRCode
                        size={120}
                        value={billCode ? billCode : ""}
                        // logoImage={logoBill}
                        logoWidth={30}
                        logoHeight={30}
                    />
                    {/* <QRCode
                        size={256}
                        style={{ height: "120px", maxWidth: "100%", width: "120px" }}
                        value={value}
                        viewBox={`0 0 256 256`}
                    /> */}
                    {/* <p className='p-4 font-bold'>Ticket is only vaild within 48 hours.</p> */}
                </div>
            </div>
            <div className="hidden">
                <div ref={contentToPrint} className="" style={{ paddingRight: '24px', fontSize: '14px' }}>
                    <div>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ width: '50px' }}><img src={ICON_GAMME + 'LogoBlack.svg'} alt="LogoBlack" /></div>
                                <div><span style={{ fontWeight: '700', fontSize: '18px' }}>KINGBETS</span></div>
                                <div style={{ width: '50px' }}></div>
                            </div>
                            <div style={{ paddingRight: '8px', color: 'black', textAlign: 'right', fontSize: '12px' }}>
                                <span>{formatDateNow()} | {formatTimeNow()} </span>
                            </div>
                            <div style={{ width: '100%', overflow: 'hidden' }}>
                                <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>----------------------------------------------------------------------------------</div>
                            </div>
                            <div>
                                <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Bill Code: {billCode}</p>
                                <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Machine Code: {machineCode}</p>
                            </div>
                            <div style={{ width: '100%', overflow: 'hidden' }}>
                                <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>----------------------------------------------------------------------------------</div>
                            </div>
                        </div>
                        <div>
                            <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black', display: 'flex', justifyContent: 'center' }}>WITHDRAW BILL </p>
                        </div>
                        <div>
                            <div style={{ paddingTop: '8px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Total withdraw</p>
                                    <p style={{ padding: '0px 8px 0px 8px', color: 'black', fontWeight: '700' }}>{"$" + formatPrice(totalAmount)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div id="qrcode-parlay"></div>
                        </div>

                        {/* <QRCode
                        size={256}
                        style={{ height: "120px", maxWidth: "100%", width: "120px" }}
                        value={value}
                        viewBox={`0 0 256 256`}
                    /> */}
                        {/* <p className='p-4 font-bold'>Ticket is only vaild within 48 hours.</p> */}
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default ModalBillRefund