import React from 'react';
import { bg_checked, checked, dot } from '../../../../../components/imgExport';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import { Radio, type RadioChangeEvent, } from 'antd';
import './style.css'
interface DayTimeProps {
    listDateFilterSoccer?: homePage_V2Api.infoListDate[]
    dateFilterSoccer?: string
    typeListGame?: "homepage" | "select"
    keyGameTab?: "gameOnline" | "gameOffline"
    totalGameOnLine?: number
    totalAllGame?: number
    changeDateFilter?: (date: string) => void
    onRadioChange?: (value: "gameOnline" | "gameOffline") => void
}
const DayTimeMLB: React.FC<DayTimeProps> = ({
    listDateFilterSoccer, dateFilterSoccer, typeListGame, totalAllGame, keyGameTab, totalGameOnLine,
    changeDateFilter, onRadioChange
}) => {
    const onChange = (e: RadioChangeEvent) => {
        onRadioChange?.(e.target.value);
    };
    return (
        <div className='flex justify-between w-full gap-2 py-3 xs:overflow-x-scroll xl:overflow-hidden'>

            <Radio.Group className={`${totalGameOnLine && totalGameOnLine > 0 ? "" : "!hidden"} xs:w-[80px] xs:h-[60px] xl:w-[12.5%] !flex !gap-2 items-center !mb-0`} value={keyGameTab} onChange={onChange} style={{ marginBottom: 16 }}>
                <Radio.Button
                    className={`!rounded-[6px] !bg-[#131620] !flex !flex-col xs:w-[80px] xs:h-[60px] lg:w-full !h-[60px] !items-center !border !justify-center !relative custom-radio-btn ${keyGameTab === "gameOnline" ? "!border-[#D4B962]" : "!border-[#202531]"}
                    `}
                    value="gameOnline"
                >
                    <div className='flex items-center gap-1'>
                        <img src={dot} alt="" />
                        <span className='xs:text-sm lg:text-[10px] xl:text-xs xl2:text-base xl3:text-base uppercase  text-[#E4E7F1] fw-[700]'>live</span>
                    </div>
                    <span className='text-[#D4B962] xs:text-[10px] lg:text-[10px] xl:text-[10px] xl2:text-xs xl3:text-xs fw-[400] whitespace-nowrap'>{totalGameOnLine ? `${totalGameOnLine} match` : "0 match"}</span>
                    <div className={`${keyGameTab === "gameOnline" ? "block" : "hidden"}`}>
                        <img src={bg_checked} alt="" className='absolute top-0 right-0' />
                        <img src={checked} alt="" className='absolute top-[6px] right-1' />
                    </div>
                </Radio.Button>
                {/* <Radio.Button
                    className={`!rounded-[6px] !bg-[#131620] !flex !flex-col w-1/2 !h-[60px] !items-center !border !justify-center !relative custom-radio-btn ${keyGameTab === "gameOffline" ? "!border-[#D4B962]" : "!border-[#202531]"}`}
                    value="gameOffline"
                >
                    <div className='flex flex-col items-center '>
                        <span className='uppercase text-base text-[#E4E7F1] fw-[700]'>Pre-Match</span>
                        <span className='text-[#D4B962] text-[12px] fw-[400]'>{totalGame ? `${totalGame} match` : `0 match`}</span>
                    </div>
                    <div className={`${keyGameTab === "gameOffline" ? "block" : "hidden"}`}>
                        <img src={bg_checked} alt="" className='absolute top-0 right-0' />
                        <img src={checked} alt="" className='absolute top-[6px] right-1' />
                    </div>
                </Radio.Button> */}
            </Radio.Group>
            {typeListGame && typeListGame === "select" && (
                <div
                    onClick={() => changeDateFilter?.("All")}
                    key={0}
                    className={`rounded-[6px] bg-[#131620] flex flex-col gap-1 xs:px-[21px] xs:py-3  xl:w-[12.5%] h-[60px] items-center justify-center !relative 
                     ${dateFilterSoccer && dateFilterSoccer === "All" && keyGameTab !== 'gameOnline' ? "border border-[#D4B962]" : "border border-[#202531]"}`}
                >

                    {/* <div className='flex flex-col items-center '> */}
                    <span className='xs:text-sm lg:text-[10px] xl:text-xs xl2:text-base xl3:text-base uppercase  text-[#E4E7F1] fw-[700] whitespace-nowrap'>
                        All game
                    </span>
                    <span className='text-[#D4B962] xs:text-[10px] lg:text-[10px] xl:text-[10px] xl2:text-xs xl3:text-xs fw-[400] whitespace-nowrap'>{totalAllGame ? `${totalAllGame} match` : `0 match`}</span>
                    {/* </div> */}
                    <div className={`${dateFilterSoccer && dateFilterSoccer === "All" && keyGameTab !== 'gameOnline' ? "block" : "hidden"}`}>
                        <img src={bg_checked} alt="" className='absolute top-0 right-0' />
                        <img src={checked} alt="" className='absolute top-[6px] right-1' />
                    </div>
                </div>
            )}

            {
                listDateFilterSoccer && listDateFilterSoccer.map((item, index) => (
                    <div
                        onClick={() => changeDateFilter?.(item.value || "")}
                        key={item.value}
                        className={`rounded-[6px] bg-[#131620] flex flex-col gap-1 xs:px-[21px] xs:py-3  xl:w-[12.5%] h-[60px] items-center justify-center !relative ${dateFilterSoccer && item.value && dateFilterSoccer.slice(0, 10) === item.value.slice(0, 10) && keyGameTab !== 'gameOnline' ? "border border-[#D4B962]" : "border border-[#202531]"}`}
                    >
                        <span className='uppercase xs:text-sm lg:text-[10px] xl:text-xs xl2:text-base xl3:text-base text-[#E4E7F1] fw-[700] whitespace-nowrap'>
                            {index === 0 ? "today" : item.date}
                        </span>
                        <span className='text-[#D4B962] xs:text-[10px] lg:text-[10px] xl:text-[10px] xl2:text-xs xl3:text-xs fw-[400] whitespace-nowrap'>{item.totalMatch ? `${item.totalMatch} match` : `0 match`}</span>
                        <div className={`${dateFilterSoccer && item.value && dateFilterSoccer.slice(0, 10) === item.value.slice(0, 10) && keyGameTab !== 'gameOnline' ? "block" : "hidden"}`}>
                            <img src={bg_checked} alt="" className='absolute top-0 right-0' />
                            <img src={checked} alt="" className='absolute top-[6px] right-1' />
                        </div>
                    </div>
                    //     <div
                    //     onClick={() => changeDateFilter?.(item.value || "")}
                    //     key={item.value}
                    //     className={`rounded-[6px] bg-[#131620] flex flex-col gap-1 xs:px-[21px] xs:py-3  sm:w-[12.5%] h-[60px] items-center justify-center !relative ${dateFilterSoccer && dateFilterSoccer.replace('-', '/') === item.date && keyGameTab !== 'gameOnline' ? "border border-[#D4B962]" : "border border-[#202531]"}`}
                    // >
                    //     <span className='uppercase xs:text-sm lg:text-[10px] xl:text-xs xl2:text-base xl3:text-base text-[#E4E7F1] fw-[700] whitespace-nowrap'>
                    //         {index === 0 ? "today" : item.date}
                    //     </span>
                    //     <span className='text-[#D4B962] xs:text-[10px] lg:text-[10px] xl:text-[10px] xl2:text-xs xl3:text-xs fw-[400] whitespace-nowrap'>{item.totalMatch ? `${item.totalMatch} match` : `0 match`}</span>
                    //     <div className={`${dateFilterSoccer && dateFilterSoccer.replace("-", "/") === item.date && keyGameTab !== 'gameOnline' ? "block" : "hidden"}`}>
                    //         <img src={bg_checked} alt="" className='absolute top-0 right-0' />
                    //         <img src={checked} alt="" className='absolute top-[6px] right-1' />
                    //     </div>
                    // </div>
                ))
            }

        </div>
    );
};

export default DayTimeMLB;
