/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import '../GameList/gameList.css'
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import { ChooseBetSoccer, lineBettingSoccer } from '../../../../../api/betting/bettingApi';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import useToast from '../../../../../hook/useToast';
import SoccerTopHDPF_V2 from './SoccerTopHDPF_V2';
import SoccerTopOUF_V2 from './SoccerTopOUF_V2';
import SoccerTop1x2F_V2 from './SoccerTop1x2F_V2';
import SoccerTopHDPH_V2 from './SoccerTopHDPH_V2';
import SoccerTopOUH_V2 from './SoccerTopOUH_V2';
import SoccerTop1x2H_V2 from './SoccerTop1x2H_V2';
import StatusBarSoccerMoreTab from './StatusBarSoccerMoreTab';
import StatusBarSoccerCorrectScore from './StatusBarSoccerCorrectScore';
import StatusBarSoccerCorrectScoreFH from './StatusBarSoccerCorrectScore_FH';
import StatusBarSoccerHTFT from './StatusBarSoccerHTFT';
import StatusBarSoccerFGLG from './StatusBarSoccerFGLG';
import SoccerTopOE_V2 from './SoccerTopOE_V2';
import { Collapse } from 'antd';
import {
    MinusOutlined,
    PlusOutlined
} from '@ant-design/icons';
import SoccerTopHDPFSideBet from './SoccerTopHDPFSideBet';
import SoccerTopOUFSideBet from './SoccerTopOUFSideBet';
import SoccerTop1x2FSideBet from './SoccerTop1x2FSideBet';
import SoccerTopOESideBet from './SoccerTopOESideBet';
import SoccerTopHDPHSideBet from './SoccerTopHDPHSideBet';
import SoccerTopOUHSideBet from './SoccerTopOUHSideBet';
import SoccerTop1x2HSideBet from './SoccerTop1x2HSideBet';
import StatusBarSoccerTotalCorners from './StatusBarSoccerTotalCorners';
import SoccerTopHDPFNew from './SoccerTopHDPFNew';
import SoccerTopOUFNew from './SoccerTopOUFNew';
import SoccerTop1x2F_V2New from './SoccerTop1x2FNew';
import SoccerTopHDPHNew from './SoccerTopHDPHNew';
import SoccerTopOENew from './SoccerTopOENew';
import SoccerTopOUHNew from './SoccerTopOUHNew';
import SoccerTop1x2HNew from './SoccerTop1x2HNew';

interface SoccerTopV2Props {
    idGame?: number | string
    homeTeam: string
    awayTeam: string
    tournament: string
    item: homePage_V2Api.InfoGameSoccer
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    listDateFilterSoccer?: homePage_V2Api.infoListDate[]
    dateFilterSoccer?: string
    chooseBetSoccer?: (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: lineBettingSoccer, dateEvent: string, option?: any, live?: boolean, idGame?: number | string
    ) => void
    checkUpdateOdds?: (id: number, point: any, prices: any[]) => void
    checkUpdateStrightBetsSlip?: (id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number) => void

}
const SoccerTopV3New: React.FC<SoccerTopV2Props> = ({
    idGame, homeTeam, awayTeam, tournament, item, listChooseBet, listAddStrightBetsSlipSoccer, dateFilterSoccer, listDateFilterSoccer,
    chooseBetSoccer, checkUpdateOdds, checkUpdateStrightBetsSlip
}) => {
    const pushToast = useToast();

    const chooseBetSoccerTopHDPF = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime HDP", item.realKickoffTime, option, false, idGame)
    }
    const chooseBetSoccerTopOUF = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime OU", item.realKickoffTime, option, false, idGame)
    }
    const chooseBetSoccerTop1x2F = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime 1x2", item.realKickoffTime, option, false, idGame)
    }
    const chooseBetSoccerOEF = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "OddEven", item.realKickoffTime, option, false, idGame)
    }

    const chooseBetSoccerTopHDPH = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half HDP", item.realKickoffTime, option, false, idGame)
    }
    const chooseBetSoccerTopOUH = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half OU", item.realKickoffTime, option, false, idGame)
    }
    const chooseBetSoccerTop1x2H = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half 1x2", item.realKickoffTime, option, false, idGame)
    }

    const chooseBetSoccerTotalGoal = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Total Goal", item.realKickoffTime, option, false, idGame)
    }

    const chooseBetSoccerDoubleChance = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Double Chance", item.realKickoffTime, option, false, idGame)
    }

    const chooseBetSoccerCorrectScore = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Correct Score", item.realKickoffTime, option, false, idGame)
    }

    const chooseBetSoccerCorrectScoreFH = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half Correct Score", item.realKickoffTime, option, false, idGame)
    }

    const chooseBetSoccerHTFT = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Half Time/Full Time", item.realKickoffTime, option, false, idGame)
    }

    const chooseBetSoccerFGLG = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Goal/Last Goal", item.realKickoffTime, option, false, idGame)
    }

    const chooseBetSoccerTotalCornersOverUnder = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime Total Corners OU", item.realKickoffTime, option, false, idGame)
    }

    const chooseBetSoccerTotalCornersHandicap = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime Total Corners HDP", item.realKickoffTime, option, false, idGame)
    }


    const chooseBetTopTotalCornersFHHandicap = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half Total Corners HDP", item.realKickoffTime, option, false, idGame)
    }

    const chooseBetTopTotalCornersFHOU = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half Total Corners OU", item.realKickoffTime, option, false, idGame)
    }

    const [oddTopHDPF, setOddTopHDPF] = useState<any[]>([])
    const [oddTopOUF, setOddTopOUF] = useState<any[]>([])
    const [oddTop1X2, setOddTop1X2] = useState<any[]>([])
    const [oddEven, setOddEven] = useState<any[]>([])

    const [oddTopHDPFH, setOddTopHDPFH] = useState<any[]>([])
    const [oddTopOUFH, setOddTopOUFH] = useState<any[]>([])
    const [oddTop1X2FH, setOddTop1X2FH] = useState<any[]>([])

    const [totalGoal, setTotalGoal] = useState<any[]>([])
    const [doubleChance, setDoubleChance] = useState<any[]>([])
    const [correctScore, setCorrectScore] = useState<any[]>([])
    const [correctScoreFH, setCorrectScoreFH] = useState<any[]>([])
    const [HTFT, setHTFT] = useState<any[]>([])
    const [FGLG, setFGLG] = useState<any[]>([])

    const [totalCornersOverUnder, setTotalCornersOverUnder] = useState<any[]>([])
    const [totalCornersHandicap, setTotalCornersHandicap] = useState<any[]>([])
    const [totalCornersFHOverUnder, setTotalCornersFHOverUnder] = useState<any[]>([])
    const [totalCornersFHHandicap, setTotalCornersFHHandicap] = useState<any[]>([])

    const [oddTopHDPFOld, setOddTopHDPFOld] = useState<any[]>([])
    const [oddTopOUFOld, setOddTopOUFOld] = useState<any[]>([])
    const [oddTop1X2Old, setOddTop1X2Old] = useState<any[]>([])

    const [oddTopHDPFHOld, setOddTopHDPFHOld] = useState<any[]>([])
    const [oddTopOUFHOld, setOddTopOUFHOld] = useState<any[]>([])
    const [oddTop1X2FHOld, setOddTop1X2FHOld] = useState<any[]>([])

    const [oddEvenOld, setOddEvenOld] = useState<any[]>([])
    const [totalGoalOld, setTotalGoalOld] = useState<any[]>([])
    const [doubleChanceOld, setDoubleChanceOld] = useState<any[]>([])
    const [correctScoreOld, setCorrectScoreOld] = useState<any[]>([])
    const [correctScoreFHOld, setCorrectScoreFHOld] = useState<any[]>([])

    const [totalCornersOverUnderOld, setTotalCornersOverUnderOld] = useState<any[]>([])
    const [totalCornersHandicapOld, setTotalCornersHandicapOld] = useState<any[]>([])
    const [totalCornersFHOverUnderOld, setTotalCornersFHOverUnderOld] = useState<any[]>([])
    const [totalCornersFHHandicapOld, setTotalCornersFHHandicapOld] = useState<any[]>([])

    const [countNumberSubbet, setCountNumberSubbet] = useState<number>(0)
    //Soccer
    const resetData = async () => {
        setOddTopHDPF([])
        setOddTopOUF([])
        setOddTop1X2([])
        setOddEven([])
        setOddTopHDPFH([])
        setOddTopOUFH([])
        setOddTop1X2FH([])
        setTotalGoal([])
        setDoubleChance([])
        setCorrectScore([])
        setCorrectScoreFH([])
        setHTFT([])
        setFGLG([])
        setTotalCornersOverUnder([])
        setTotalCornersHandicap([])
        setTotalCornersFHOverUnder([])
        setTotalCornersFHHandicap([])
    }
    const gOddsByTournamentId = async () => {
        // pushLoading(true);
        const ketQua = await homePage_V2Api.getOddsSoccerByGameId(item.id);
        if (ketQua?.success) {
            const Subbet = ketQua.data.filter((item) => (item.marketType === "FH_CorrectScore" || item.marketType === "TotalGoal" || item.marketType === "DoubleChance" || item.marketType === "CorrectScore"))
            const SubbetTotalCorners = ketQua.data.filter((item) => (item.marketType === "TotalCorners_FH_OverUnder" || item.marketType === "TotalCorners_FH_Handicap" || item.marketType === "TotalCorners_OverUnder" || item.marketType === "TotalCorners_Handicap"))
            const filterSubbet: any[] = Array.from(new Map(Subbet.map(item => [item.marketType, item])).values());
            setCountNumberSubbet(+ SubbetTotalCorners.length ? Number(filterSubbet.length) + 1 : Number(filterSubbet.length))
            const Handicap = ketQua.data.filter((item) => item.marketType === "Handicap")
            setOddTopHDPF(Handicap);
            const OverUnder = ketQua.data.filter((item) => item.marketType === "OverUnder")
            setOddTopOUF(OverUnder);
            const _1X2 = ketQua.data.filter((item) => item.marketType === "_1X2")
            setOddTop1X2(_1X2);
            const OddEven = ketQua.data.filter((item) => item.marketType === "OddEven")
            setOddEven(OddEven);
            const FH_Handicap = ketQua.data.filter((item) => item.marketType === "FH_Handicap")
            setOddTopHDPFH(FH_Handicap);
            const FH_OverUnder = ketQua.data.filter((item) => item.marketType === "FH_OverUnder")
            setOddTopOUFH(FH_OverUnder);
            const FH_1X2 = ketQua.data.filter((item) => item.marketType === "FH_1X2")
            setOddTop1X2FH(FH_1X2);
            const TotalGoal = ketQua.data.filter((item) => item.marketType === "TotalGoal")
            setTotalGoal(TotalGoal);
            const DoubleChance = ketQua.data.filter((item) => item.marketType === "DoubleChance")
            setDoubleChance(DoubleChance);
            const CorrectScore = ketQua.data.filter((item) => item.marketType === "CorrectScore")
            setCorrectScore(CorrectScore);
            const correctScoreFH = ketQua.data.filter((item) => item.marketType === "FH_CorrectScore")
            setCorrectScoreFH(correctScoreFH);
            const HTFTParam = ketQua.data.filter((item) => item.marketType === "HTFT")
            setHTFT(HTFTParam);
            const FGLGParam = ketQua.data.filter((item) => item.marketType === "FGLG")
            setFGLG(FGLGParam);

            const TotalCornersOverUnderParam = ketQua.data.filter((item) => item.marketType === "TotalCorners_OverUnder")
            setTotalCornersOverUnder(TotalCornersOverUnderParam);


            const TotalCornersHandicapParam = ketQua.data.filter((item) => item.marketType === "TotalCorners_Handicap")
            setTotalCornersHandicap(TotalCornersHandicapParam);


            const TotalCornersFHOverUnderParam = ketQua.data.filter((item) => item.marketType === "TotalCorners_FH_OverUnder")
            setTotalCornersFHOverUnder(TotalCornersFHOverUnderParam);


            const TotalCornersFHHandicapParam = ketQua.data.filter((item) => item.marketType === "TotalCorners_FH_Handicap")
            setTotalCornersFHHandicap(TotalCornersFHHandicapParam);
        } else {
            // setListGameSoccer([]);
            pushToast(ketQua?.message, "warn");
        }
        // pushLoading(false);
    }

    useEffect(() => {
        if (item.id)
            gOddsByTournamentId()
    }, [])

    useEffect(() => {
        if (item.id && dateFilterSoccer) {
            resetData()
            setTimeout(() => { gOddsByTournamentId() }, 500)
        }
    }, [item])

    useEffect(() => {
        setOddTopHDPFOld(oddTopHDPF)
        setOddTopHDPF(item.odds?.filter((item) => item.marketType === "Handicap") ? item.odds?.filter((item) => item.marketType === "Handicap") : [])

        setOddTopOUFOld(oddTopOUF)
        setOddTopOUF(item.odds?.filter((item) => item.marketType === "OverUnder") ? item.odds?.filter((item) => item.marketType === "OverUnder") : [])

        setOddTop1X2Old(oddTop1X2)
        setOddTop1X2(item.odds?.filter((item) => item.marketType === "_1X2") ? item.odds?.filter((item) => item.marketType === "_1X2") : [])

        setOddTopHDPFHOld(oddTopHDPFH)
        setOddTopHDPFH(item.odds?.filter((item) => item.marketType === "FH_Handicap") ? item.odds?.filter((item) => item.marketType === "FH_Handicap") : [])

        setOddTopOUFHOld(oddTopOUFH)
        setOddTopOUFH(item.odds?.filter((item) => item.marketType === "FH_OverUnder") ? item.odds?.filter((item) => item.marketType === "FH_OverUnder") : [])

        setOddTop1X2FHOld(oddTop1X2FH)
        setOddTop1X2FH(item.odds?.filter((item) => item.marketType === "FH_1X2") ? item.odds?.filter((item) => item.marketType === "FH_1X2") : [])

        setOddEvenOld(oddEven)
        setOddEven(item.odds?.filter((item) => item.marketType === "OddEven") ? item.odds?.filter((item) => item.marketType === "OddEven") : [])

        setTotalGoalOld(totalGoal)
        setTotalGoal(item.odds?.filter((item) => item.marketType === "TotalGoal") ? item.odds?.filter((item) => item.marketType === "TotalGoal") : [])

        setDoubleChanceOld(doubleChance)
        setDoubleChance(item.odds?.filter((item) => item.marketType === "DoubleChance") ? item.odds?.filter((item) => item.marketType === "DoubleChance") : [])

        setCorrectScoreOld(correctScore)
        setCorrectScore(item.odds?.filter((item) => item.marketType === "CorrectScore") ? item.odds?.filter((item) => item.marketType === "CorrectScore") : [])

        setCorrectScoreFHOld(correctScoreFH)
        setCorrectScoreFH(item.odds?.filter((item) => item.marketType === "FH_CorrectScore") ? item.odds?.filter((item) => item.marketType === "FH_CorrectScore") : [])

        setTotalCornersOverUnderOld(totalCornersOverUnder)
        setTotalCornersOverUnder(item.odds?.filter((item) => item.marketType === "TotalCorners_OverUnder") ? item.odds?.filter((item) => item.marketType === "TotalCorners_OverUnder") : [])

        setTotalCornersHandicapOld(totalCornersHandicap)
        setTotalCornersHandicap(item.odds?.filter((item) => item.marketType === "TotalCorners_Handicap") ? item.odds?.filter((item) => item.marketType === "TotalCorners_Handicap") : [])

        setTotalCornersFHOverUnderOld(totalCornersFHOverUnder)
        setTotalCornersFHOverUnder(item.odds?.filter((item) => item.marketType === "TotalCorners_FH_OverUnder") ? item.odds?.filter((item) => item.marketType === "TotalCorners_FH_OverUnder") : [])

        setTotalCornersFHHandicapOld(totalCornersFHHandicap)
        setTotalCornersFHHandicap(item.odds?.filter((item) => item.marketType === "TotalCorners_FH_Handicap") ? item.odds?.filter((item) => item.marketType === "TotalCorners_FH_Handicap") : [])
    }, [item])

    return (
        <>
            <div className='flex borderLine detailTop'>
                <div className='pb-[10px] w-full'>
                    <div>
                        <div className='flex flex-col'>
                            <div className="gameRatio flex">
                                <div className="homeRaito w-[57%] flex justify-between">
                                    {/* <SoccerTopA1x2F_V2 /> */}
                                    <SoccerTopHDPFNew
                                        homeTeam={homeTeam}
                                        awayTeam={awayTeam}
                                        item={oddTopHDPF}
                                        oddsOld={oddTopHDPFOld}
                                        listChooseBet={listChooseBet}
                                        chooseBetTop={chooseBetSoccerTopHDPF}
                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                        dateFilterSoccer={dateFilterSoccer}
                                        listDateFilterSoccer={listDateFilterSoccer}
                                    />
                                    <SoccerTopOUFNew
                                        homeTeam={homeTeam}
                                        awayTeam={awayTeam}
                                        item={oddTopOUF}
                                        oddsOld={oddTopOUFOld}
                                        listChooseBet={listChooseBet}
                                        chooseBetTop={chooseBetSoccerTopOUF}
                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                        dateFilterSoccer={dateFilterSoccer}
                                        listDateFilterSoccer={listDateFilterSoccer}
                                    />
                                    <SoccerTop1x2F_V2New
                                        homeTeam={homeTeam}
                                        awayTeam={awayTeam}
                                        item={oddTop1X2}
                                        oddsOld={oddTop1X2Old}
                                        listChooseBet={listChooseBet}
                                        chooseBetTop={chooseBetSoccerTop1x2F}
                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                        dateFilterSoccer={dateFilterSoccer}
                                        listDateFilterSoccer={listDateFilterSoccer}
                                    />
                                    <SoccerTopOENew
                                        homeTeam={homeTeam}
                                        awayTeam={awayTeam}
                                        item={oddEven}
                                        oddsOld={oddEvenOld}
                                        listChooseBet={listChooseBet}
                                        chooseBetTop={chooseBetSoccerOEF}
                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                        dateFilterSoccer={dateFilterSoccer}
                                        listDateFilterSoccer={listDateFilterSoccer}
                                    />
                                </div>
                                <div className="awayRaito w-[43%] flex justify-between">
                                    <SoccerTopHDPHNew
                                        homeTeam={homeTeam}
                                        awayTeam={awayTeam}
                                        item={oddTopHDPFH}
                                        oddsOld={oddTopHDPFHOld}
                                        listChooseBet={listChooseBet}
                                        chooseBetTop={chooseBetSoccerTopHDPH}
                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                        dateFilterSoccer={dateFilterSoccer}
                                        listDateFilterSoccer={listDateFilterSoccer}
                                    />
                                    <SoccerTopOUHNew
                                        homeTeam={homeTeam}
                                        awayTeam={awayTeam}
                                        item={oddTopOUFH}
                                        oddsOld={oddTopOUFHOld}
                                        listChooseBet={listChooseBet}
                                        chooseBetTop={chooseBetSoccerTopOUH}
                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                        dateFilterSoccer={dateFilterSoccer}
                                        listDateFilterSoccer={listDateFilterSoccer}
                                    />
                                    <SoccerTop1x2HNew
                                        homeTeam={homeTeam}
                                        awayTeam={awayTeam}
                                        item={oddTop1X2FH}
                                        oddsOld={oddTop1X2FHOld}
                                        listChooseBet={listChooseBet}
                                        chooseBetTop={chooseBetSoccerTop1x2H}
                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                        dateFilterSoccer={dateFilterSoccer}
                                        listDateFilterSoccer={listDateFilterSoccer}
                                    />
                                </div>
                            </div>
                            <Collapse
                                className='collapse-soccer-live w-full !border !border-[#202531]'
                                expandIcon={
                                    (expanded) => (
                                        expanded.isActive ? (
                                            <span className='!text-base fw-[400] !text-[#C1C9E1]'>View less<MinusOutlined className='pl-2' /></span>
                                        ) : (
                                            <span className='!text-base fw-[400] !text-[#C1C9E1]'>View more {countNumberSubbet !== 0 ? `(${countNumberSubbet})` : ""}<PlusOutlined className='pl-2' /></span>
                                        )
                                    )
                                }
                                items={[{
                                    key: item.id,
                                    children:
                                        <div className='flex flex-col'>
                                            <div className="gameRatio flex">
                                                <div className="homeRaito flex flex-col w-full">
                                                    <StatusBarSoccerMoreTab
                                                        oddsEven={[]}
                                                        oddsEvenOld={[]}
                                                        totalGoal={totalGoal}
                                                        totalGoalOld={totalGoalOld}
                                                        doubleChance={doubleChance}
                                                        doubleChanceOld={doubleChanceOld}
                                                        homeTeam={homeTeam}
                                                        awayTeam={awayTeam}
                                                        listChooseBet={listChooseBet}
                                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                        chooseBetTop={chooseBetSoccerTotalGoal}
                                                        chooseBetTopDoubleChance={chooseBetSoccerDoubleChance}
                                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                        dateFilterSoccer={dateFilterSoccer}
                                                        listDateFilterSoccer={listDateFilterSoccer}
                                                    />
                                                    <StatusBarSoccerCorrectScoreFH
                                                        correctScoreFH={correctScoreFH}
                                                        correctScoreFHOld={correctScoreFHOld}
                                                        homeTeam={homeTeam}
                                                        awayTeam={awayTeam}
                                                        listChooseBet={listChooseBet}
                                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                        chooseBetTop={chooseBetSoccerCorrectScoreFH}
                                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                        dateFilterSoccer={dateFilterSoccer}
                                                        listDateFilterSoccer={listDateFilterSoccer}
                                                    />
                                                    <StatusBarSoccerCorrectScore
                                                        correctScore={correctScore}
                                                        correctScoreOld={correctScoreOld}
                                                        homeTeam={homeTeam}
                                                        awayTeam={awayTeam}
                                                        listChooseBet={listChooseBet}
                                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                        chooseBetTop={chooseBetSoccerCorrectScore}
                                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                        dateFilterSoccer={dateFilterSoccer}
                                                        listDateFilterSoccer={listDateFilterSoccer}
                                                    />

                                                    <StatusBarSoccerTotalCorners
                                                        totalGoal={totalGoal}
                                                        totalGoalOld={totalGoalOld}

                                                        totalCornersOverUnder={totalCornersOverUnder}
                                                        totalCornersHandicap={totalCornersHandicap}
                                                        totalCornersFHOverUnder={totalCornersFHOverUnder}
                                                        totalCornersFHHandicap={totalCornersFHHandicap}
                                                        totalCornersOverUnderOld={totalCornersOverUnderOld}
                                                        totalCornersHandicapOld={totalCornersHandicapOld}
                                                        totalCornersFHOverUnderOld={totalCornersFHOverUnderOld}
                                                        totalCornersFHHandicapOld={totalCornersFHHandicapOld}

                                                        homeTeam={homeTeam}
                                                        awayTeam={awayTeam}
                                                        listChooseBet={listChooseBet}
                                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                        chooseBetTop={chooseBetSoccerTotalCornersOverUnder}
                                                        chooseBetTopTotalCornersHandicap={chooseBetSoccerTotalCornersHandicap}
                                                        chooseBetTopTotalCornersFHHandicap={chooseBetTopTotalCornersFHHandicap}
                                                        chooseBetTopTotalCornersFHOU={chooseBetTopTotalCornersFHOU}
                                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                        dateFilterSoccer={dateFilterSoccer}
                                                        listDateFilterSoccer={listDateFilterSoccer}
                                                    />

                                                    {/* <StatusBarSoccerHTFT
                                                    HTFT={HTFT}
                                                    homeTeam={homeTeam}
                                                    awayTeam={awayTeam}
                                                    listChooseBet={listChooseBet}
                                                    chooseBetTop={chooseBetSoccerHTFT}
                                                />
                                                <StatusBarSoccerFGLG
                                                    FGLG={FGLG}
                                                    homeTeam={homeTeam}
                                                    awayTeam={awayTeam}
                                                    listChooseBet={listChooseBet}
                                                    chooseBetTop={chooseBetSoccerFGLG}
                                                /> */}
                                                </div>
                                            </div>
                                        </div>

                                }]}
                            />
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default SoccerTopV3New;