/* eslint-disable react-hooks/exhaustive-deps */
import HeadNavi from "../homepage/components/MainNavi/Head/HeadNavi";
import { useEffect, useState } from "react";
import { itemMenu } from "../homepage/components/MainNavi/Tabs/TabsNaviV2";
import { soccerActice, soccer, baketballActive, baketball, rugbyActive, rugby, baseballActive, baseball, Dollar, calendar, TransactionWhite } from "../../components/imgExport";
import { SoccerStatement } from "./Component/SoccerStatement";
import { Layout } from "../../routes/Layout";
import useLoading from '../../hook/useLoading';
import useToast from '../../hook/useToast';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert } from '../../store/slices/toastSlice';
import * as myBetApi from '../../api/myBet/myBetApi';
import './style.css'
import useThrottle from "../../hook/useThrottle";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, TimeRangePickerProps } from "antd";
import { getPresentMoney } from "../../api/billAcceptor/billAcceptorApi";
import { selectorUser, setUserMoney } from "../../store/slices/userSlice";
import { formatPrice } from "../../until";
const { RangePicker } = DatePicker;
export const Statement: React.FC = () => {
    const { presentMoney } = useSelector(selectorUser);
    const pushToast = useToast();
    const pushLoading = useLoading();
    const dispatch = useDispatch();
    var startDateFirst = new Date(dayjs().startOf('week').toString()).getFullYear().toString() + "-" + (new Date(dayjs().startOf('week').toString()).getMonth() + 1 > 9 ? new Date(dayjs().startOf('week').toString()).getMonth() + 1 : "0" + new Date(dayjs().startOf('week').toString()).getMonth() + 1) + "-" + (new Date(dayjs().startOf('week').toString()).getDate() > 9 ? new Date(dayjs().startOf('week').toString()).getDate() + 1 : "0" + new Date(dayjs().startOf('week').toString()).getDate())
    var endDateFirst = new Date(dayjs().toString()).getFullYear().toString() + "-" + (new Date(dayjs().toString()).getMonth() + 1 > 9 ? new Date(dayjs().toString()).getMonth() + 1 : "0" + new Date(dayjs().toString()).getMonth() + 1) + "-" + (new Date(dayjs().toString()).getDate() > 9 ? new Date(dayjs().toString()).getDate() + 1 : "0" + new Date(dayjs().toString()).getDate())
    const [tournaments, setTournaments] = useState(itemMenu[0])
    const [chooseGame, setChooseGame] = useState<string>("Soccer")
    const [startDate, setStartDate] = useState<string>(startDateFirst)
    const [endDate, setEndDate] = useState<string>(endDateFirst)
    const [limit, setLimit] = useState<number>(20)
    const [offset, setOffset] = useState<number>(0)
    const [listBill, setListBill] = useState<any>([])

    const [money, setMoney] = useState("")
    const [time, setTime] = useState(0)
    const handelChooseTournaments = (value: itemMenu) => {
        const listParent = document.querySelectorAll(".ant-menu-parent-tournament.ant-menu-submenu-selected")
        if (listParent.length > 0) {
            listParent[0].classList.remove("ant-menu-submenu-selected")
        }

        const listChildren = document.querySelectorAll(".ant-menu-children-tournament.ant-menu-item-selected")
        if (listChildren.length > 0) {
            listChildren[0].classList.remove("ant-menu-item-selected")
        }

        setTournaments(value)
        if (value.id !== 9) {
            setTournaments(value)
            // gListGameByTournament(value.id)
            // gListAllBetMGM(value.name)
        }
        if (value.id === 0) {
            // gListOfGamesForTheHomepage()
        }
        window.location.href = value.href
        // navigator(value.href)
    }

    const getCurrentMoney = useThrottle(async () => {
        pushLoading(true);
        var timeTotal = 10
        const interval = setInterval(() => {
            if (timeTotal > 0) {
                timeTotal -= 1
                setTime(timeTotal)
            } else {
                clearInterval(interval)
            }
        }, 1000);
        const ketQua = await getPresentMoney();
        if (ketQua?.success) {
            dispatch(setUserMoney(ketQua.data.currentMoney))
            setMoney(ketQua.data.currentMoney)
        }
        setTimeout(() => {
            pushLoading(false);
        }, 1000)
    }, 10000)

    const getMyBetRunning = useThrottle(async () => {
        pushLoading(true)
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const ketQua = await myBetApi.myBetStatement(chooseGame, timeZone, limit, offset, startDate, endDate)
        if (ketQua?.success) {
            setListBill(ketQua.data)
        } else {
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        // 
        pushLoading(false)
    }, 100)

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            // console.log('From: ', dates[0], ', to: ', dates[1]);
            // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            setStartDate && setStartDate(dateStrings[0])
            setEndDate && setEndDate(dateStrings[1])
        } else {
            console.log('Clear');
        }
    };
    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'This day', value: [dayjs('presets'), dayjs()] },
        { label: 'This week', value: [dayjs().startOf('week'), dayjs()] },
        { label: 'Last week', value: [dayjs().startOf('week').add(-7, 'd'), dayjs().endOf('week').add(-7, 'd')] },
        { label: 'Past 7 Day', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'This month', value: [dayjs().startOf('month'), dayjs()] },
        { label: 'Last month', value: [dayjs().startOf('month').add(-30, 'd'), (dayjs().startOf('month').add(-30, 'd')).endOf('month')] },
    ];

    useEffect(() => {
        getCurrentMoney()
    }, [chooseGame])

    useEffect(() => {
        getMyBetRunning()
    }, [startDate, endDate, chooseGame])

    return (
        <Layout>
            <div className='bg-[#131620]  overflow-hidden xs:h-[calc(100vh-120px)] xl:!h-[100vh] w-full'>
                <HeadNavi
                    tournaments={tournaments}
                    setTournaments={handelChooseTournaments}
                />
                <div className="w-full h-full flex justify-center items-center xs:mt-[50px] xl:mt-0 ">
                    <div className="xs:w-full xl:w-[1200px]  xl3:w-[1375px] xs:ml-0 xl:ml-[100px] xl3:ml-0 flex h-full">
                        <div className="  flex flex-col items-center justify-center w-full xs:px-0 md:px-5">
                            <div className="flex items-center w-full bg-gradient-A98F34 px-6 border-r border-r-[#202530] border-l border-l-[#202530] border-t border-t-[#202530]">
                                <span className={`text-[18px] ] font-medium py-2 pr-12 text-[#E4E7F1] `}>
                                    STATEMENT
                                </span>
                            </div>
                            <div className="w-full ">
                                {/* Mobile */}
                                <div className="xs:flex md:hidden items-center justify-between px-3 pt-6">

                                    <div className="flex items-start  gap-2">
                                        <img src={Dollar} alt="" />
                                        <div className="flex flex-col gap-1">
                                            <span className="font-bold text-xs text-[#E4E7F1] uppercase ">opening Balance</span>
                                            <span className="font-bold text-xs text-[#C59D0F] uppercase">${formatPrice(presentMoney || 0)}</span>
                                            <div className='text-xs pl-1 cursor-pointer text-white flex items-center gap-2' onClick={() => getCurrentMoney()}>
                                                {time && time > 0 ? time : <img src={TransactionWhite} alt="Transaction" className='h-[12px] aspect-square' />}
                                                <span className='text-xs fw-[400] uppercase text-white h-fit'>Reload</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="flex items-center rounded-lg border border-[#4B5563] px-4 py-3">
                                        <img src={calendar} alt="" />
                                        <RangePicker
                                            presets={rangePresets}
                                            onChange={onRangeChange}
                                            size="small"
                                            suffixIcon={false}
                                            defaultValue={[dayjs().startOf('week'), dayjs()]}
                                            value={[dayjs(startDate), dayjs(endDate)]}
                                            variant="borderless"
                                            className="custom-picker"
                                            popupClassName="custom-popup-picker-mobile"
                                            renderExtraFooter={() => <div className="flex w-full gap-4 pb-4">
                                                <button className="w-full bg-[#131620] rounded-lg border border-[#C1C9E1] text-[#C1C9E1] text-xs font-medium py-2">
                                                    Cancel
                                                </button>
                                                <button className="w-full bg-[#D4B962] rounded-lg   text-[#FFFFFF] text-xs font-medium py-2">
                                                    OK
                                                </button>
                                            </div>
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="w-full flex items-center xs:px-6 md:px-0 xs:justify-between md:justify-center py-6 border-r border-r-[#202530] border-l border-l-[#202530]">
                                    <div className="flex items-center gap-2 xs:border-0 md:border-r md:border-r-[#212632]"
                                        onClick={() => { setChooseGame('Soccer'); setLimit(20); setOffset(0); }}
                                    >
                                        <img src={`${chooseGame === "Soccer" ? soccerActice : soccer}`} alt="" />
                                        <span
                                            className={`uppercase text-sm xs:pr-0  md:pr-[51px] ${chooseGame === "Soccer" ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                        >
                                            soccer
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-2 xs:border-0 md:border-r md:border-r-[#212632]"
                                        onClick={() => { setChooseGame('NBA'); setLimit(20); setOffset(0); }}
                                    >
                                        <img src={`${chooseGame === "NBA" ? baketballActive : baketball}`} alt="" className="xs:pl-0 md:pl-[51px]" />
                                        <span className={`uppercase text-sm  xs:pr-0  md:pr-[51px] ${chooseGame === "NBA" ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>nba</span>
                                    </div>
                                    <div className="flex items-center gap-2 xs:border-0 md:border-r md:border-r-[#212632]"
                                        onClick={() => { setChooseGame('NFL'); setLimit(20); setOffset(0); }}
                                    >
                                        <img src={`${chooseGame === "NFL" ? rugbyActive : rugby}`} alt="" className="xs:pl-0 md:pl-[51px]" />
                                        <span className={`uppercase text-sm  xs:pr-0  md:pr-[51px] ${chooseGame === "NFL" ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>nfl</span>
                                    </div>
                                    <div className="flex items-center gap-2 "
                                        onClick={() => { setChooseGame('MLB'); setLimit(20); setOffset(0); }}
                                    >
                                        <img src={`${chooseGame === "MLB" ? baseballActive : baseball}`} alt="" className="xs:pl-0 md:pl-[51px]" />
                                        <span className={`uppercase text-sm   ${chooseGame === "MLB" ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>mlb</span>
                                    </div>
                                </div>
                                <SoccerStatement money={Number(money)} listBill={listBill} startDate={startDate} endDate={endDate} setStartDate={(e: any) => setStartDate(e)} setEndDate={(e: any) => setEndDate(e)} time={time} getCurrentMoney={getCurrentMoney} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    )
}