import { soccer_2, cup, LogoWithoutText, liveIcon } from "../../../../components/imgExport"
interface ItemProp {
    item?: any
    status: number
}
export const ItemSoccerSettled: React.FC<ItemProp> = ({
    item,
    status
}) => {
    console.log(item)
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return (
        <div>
            <div className="xs:hidden md:flex w-full border-b border-b-[#202531] py-[18px]">
                <div className="flex justify-center items-center w-[15%]">
                    <div className="flex flex-col items-start gap-3">
                        <span className="text-sm text-[#E4E7F1]">ID {item.id}</span>
                        <span className="text-sm text-[#E4E7F1]">{`${(new Date(item.date).toLocaleString('en', { timeZone: timeZone }))}`}</span>
                    </div>
                </div>
                <div className="flex flex-col gap-2 items-start w-[35%] px-3">

                    {item.billDetails.map((itemBillDetail: any, indexBillDetail: any) => (
                        <>
                            <div className="flex items-center gap-2" key={indexBillDetail}>
                                <img src={soccer_2} alt="" />
                                <span className="text-xs text-[#C1C9E1] font-medium">{itemBillDetail.lineBetting}</span>
                            </div>
                            <div className="rounded-[4px] py-1 bg-[#212531]">
                                <span className="px-3  text-sm text-[#E4E7F1]">{`${itemBillDetail.team} @ ${itemBillDetail.score}`}</span>
                            </div>
                            <div className="flex gap-4">
                                <img src={cup} alt="" />
                                <div className="flex flex-col items-start gap-1">
                                    <span className="text-sm text-[#E4E7F1]">{itemBillDetail.tournament}</span>
                                    {itemBillDetail.stage === "1" || itemBillDetail.stage === 1 ?
                                        <>
                                            <div className="flex gap-2">
                                                <img src={liveIcon} alt="" />
                                                <span className="text-xs text-[#E4E7F1]">{`LIVE ${itemBillDetail.homeLiveScore} : ${itemBillDetail.awayLiveScore} `}</span>
                                            </div>
                                        </>
                                        :
                                        <span className="text-xs text-[#E4E7F1]">{`${(new Date(itemBillDetail.dateEvent).toLocaleString('en', { timeZone: timeZone }))}`}</span>
                                    }
                                </div>
                            </div>
                            <div className='flex items-center gap-4'>
                                <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                                    <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                </div>
                                <span className='text-[#E4E7F1] fw-[500] text-xs'>{itemBillDetail.game.split('/')[0]}</span>
                            </div>
                            <div className='flex items-center gap-4'>
                                <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                                    <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                </div>
                                <span className='text-[#E4E7F1] fw-[500] text-xs'>{itemBillDetail.game.split('/')[1]}</span>
                            </div>
                        </>
                    ))}
                </div>
                <div className="flex flex-col justify-between items-center w-[10%] py-20">
                    {item.billDetails.map((itemBillDetail: any, indexBillDetail: any) => (
                        <span className="text-sm text-[#E4E7F1]">
                            {itemBillDetail.sport === "Soccer" ?
                                //soccer
                                (itemBillDetail.lineBetting.includes("Corners")
                                    ?
                                    //lấy tỉ số phạt góc
                                    `HT: ${Number(itemBillDetail.billDetailResult.homeCornersQ1)}:${Number(itemBillDetail.billDetailResult.awayCornersQ1)} / FT ${Number(itemBillDetail.billDetailResult.homeCornersQ2)}:${Number(itemBillDetail.billDetailResult.awayCornersQ2)}`
                                    :
                                    //lấy tỉ số trận đấu
                                    `HT: ${Number(itemBillDetail.billDetailResult.homeQ1)}:${Number(itemBillDetail.billDetailResult.awayQ1)} / FT: ${Number(itemBillDetail.billDetailResult.homeQ2)}:${Number(itemBillDetail.billDetailResult.awayQ2)}`
                                )
                                :
                                (
                                    itemBillDetail.sport === "NBA" || itemBillDetail.sport === "NFL"
                                        ?
                                        // trường hợp NBA và NFL là tổng 4 hiệp
                                        `HT: 
                                        ${Number(itemBillDetail.billDetailResult.homeQ1) +
                                        Number(itemBillDetail.billDetailResult.homeQ2)}
                                            :
                                            ${Number(itemBillDetail.billDetailResult.awayQ1) +
                                        Number(itemBillDetail.billDetailResult.awayQ2)
                                        }
                                         / FT: 
                                         ${Number(itemBillDetail.billDetailResult.homeQ1) +
                                        Number(itemBillDetail.billDetailResult.homeQ2) +
                                        Number(itemBillDetail.billDetailResult.homeQ3) +
                                        Number(itemBillDetail.billDetailResult.homeQ4)}
                                        :${Number(itemBillDetail.billDetailResult.awayQ1) +
                                        Number(itemBillDetail.billDetailResult.awayQ2) +
                                        Number(itemBillDetail.billDetailResult.awayQ3) +
                                        Number(itemBillDetail.billDetailResult.awayQ4)
                                        }`
                                        :
                                        // trường hợp MLB thì tổng là tất cả các hiệp
                                        `HT: 
                                        ${Number(itemBillDetail.billDetailResult.homeQ1) +
                                        Number(itemBillDetail.billDetailResult.homeQ2) +
                                        Number(itemBillDetail.billDetailResult.homeQ3) +
                                        Number(itemBillDetail.billDetailResult.homeQ4) +
                                        Number(itemBillDetail.billDetailResult.homeQ5)
                                        }
                                        :
                                        ${Number(itemBillDetail.billDetailResult.awayQ1) +
                                        Number(itemBillDetail.billDetailResult.awayQ2) +
                                        Number(itemBillDetail.billDetailResult.awayQ3) +
                                        Number(itemBillDetail.billDetailResult.awayQ4) +
                                        Number(itemBillDetail.billDetailResult.awayQ5)
                                        }
                                         / FT: 
                                         ${Number(itemBillDetail.billDetailResult.homeQ1) +
                                        Number(itemBillDetail.billDetailResult.homeQ2) +
                                        Number(itemBillDetail.billDetailResult.homeQ3) +
                                        Number(itemBillDetail.billDetailResult.homeQ4) +
                                        Number(itemBillDetail.billDetailResult.homeQ5) +
                                        Number(itemBillDetail.billDetailResult.homeQ6) +
                                        Number(itemBillDetail.billDetailResult.homeQ7) +
                                        Number(itemBillDetail.billDetailResult.homeQ8) +
                                        Number(itemBillDetail.billDetailResult.homeQ9) +
                                        Number(itemBillDetail.billDetailResult.homeOt)
                                        }
                                        :
                                        ${Number(itemBillDetail.billDetailResult.awayQ1) +
                                        Number(itemBillDetail.billDetailResult.awayQ2) +
                                        Number(itemBillDetail.billDetailResult.awayQ3) +
                                        Number(itemBillDetail.billDetailResult.awayQ4) +
                                        Number(itemBillDetail.billDetailResult.awayQ5) +
                                        Number(itemBillDetail.billDetailResult.awayQ6) +
                                        Number(itemBillDetail.billDetailResult.awayQ7) +
                                        Number(itemBillDetail.billDetailResult.awayQ8) +
                                        Number(itemBillDetail.billDetailResult.awayQ9) +
                                        Number(itemBillDetail.billDetailResult.awayOt)
                                        }`

                                )
                            }
                        </span>
                    ))}
                </div>
                <div className="flex flex-col justify-between items-center w-[10%] py-20">
                    {item.billDetails.map((itemBillDetail: any, indexBillDetail: any) => (
                        <span className="text-sm text-[#E4E7F1]">{itemBillDetail.score.split(' ').length > 1 ? itemBillDetail.score.split(' ')[1] : itemBillDetail.score.split(' ')[0]}</span>

                    ))}
                </div>
                <div className="flex justify-center items-center w-[10%]">
                    <span className="text-sm text-[#D4B962]">${item.totalStake}</span>
                </div>
                <div className="flex justify-center items-center w-[10%]">
                    <span className={`text-sm ${(item.result === 1 && 'text-[#33E49A]') || (item.result === 2 && ' text-[#FF4A59]') || (item.result === 3 && ' text-[#666E97]') || (item.result === 0 && 'text-[#D4B962]')}`}>${item.winLose ? item.winLose : 0}</span>
                </div>
                <div className="flex justify-center items-center w-[10%]">
                    <span className={`text-sm ${(item.result === 1 && 'text-[#33E49A]') || (item.result === 2 && ' text-[#FF4A59]') || (item.result === 3 && ' text-[#666E97]') || (item.result === 0 && 'text-[#D4B962]')}`}>
                        {(item.result === 1 && (item.isHalf ? 'WIN 1/2' : "WIN")) || (item.result === 2 && (item.isHalf ? ' LOSE  1/2' : " LOSE ")) || (item.result === 3 && 'CANCEL') || (item.result === 0 && 'DRAW')}
                    </span>
                </div>
            </div>

            <div className="xs:flex md:hidden flex-col gap-3 rounded-xl border border-[#202531] p-3">
                <div className="flex justify-between items-center">
                    <span className="text-sm text-[#E4E7F1]">ID {item.id}</span>
                    <span className="text-sm text-[#E4E7F1]">{`${(new Date(item.date).toLocaleString('en', { timeZone: timeZone }))}`}</span>
                </div>


                {item.billDetails.map((itemBillDetail: any, indexBillDetail: any) => (
                    <>
                        <div className="flex items-center gap-2" key={indexBillDetail}>
                            <img src={soccer_2} alt="" />
                            <span className="text-xs text-[#C1C9E1] font-medium">{itemBillDetail.lineBetting}</span>
                        </div>
                        <div className="rounded-[4px] py-1 bg-[#212531] w-full">
                            <span className="px-3  text-sm text-[#E4E7F1]">{`${itemBillDetail.team} @ ${itemBillDetail.score}`}</span>
                        </div>
                        <div className='flex items-center gap-4'>
                            <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                                <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                            </div>
                            <span className='text-[#E4E7F1] fw-[500] text-xs'>{itemBillDetail.game.split('/')[0]}</span>
                        </div>
                        <div className='flex items-center gap-4'>
                            <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                                <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                            </div>
                            <span className='text-[#E4E7F1] fw-[500] text-xs'>{itemBillDetail.game.split('/')[1]}</span>
                        </div>
                        <div className="flex items-center justify-between">
                            <span className="text-sm font-bold uppercase text-[#E4E7F1]">Tournament</span>
                            <span className="text-sm text-[#E4E7F1]">{itemBillDetail.tournament}</span>
                        </div>

                        {itemBillDetail.stage ?
                            <div className="flex items-center justify-between">
                                <span className="text-sm font-bold uppercase text-[#E4E7F1]">Live</span>
                                <span className="text-sm text-[#E4E7F1]">{` ${itemBillDetail.homeLiveScore} : ${itemBillDetail.awayLiveScore}`}</span>
                            </div>
                            :
                            <div className="flex items-center justify-between">
                                <span className="text-sm font-bold uppercase text-[#E4E7F1]">Date Event</span>
                                <span className="text-sm text-[#E4E7F1]">{`${(new Date(itemBillDetail.dateEvent).toLocaleString('en', { timeZone: timeZone }))}`}</span>
                            </div>
                        }

                        <div className="flex items-center justify-between">
                            <span className="text-sm font-bold uppercase text-[#E4E7F1]">score</span>
                            <span className="text-sm text-[#E4E7F1]">
                                {itemBillDetail.sport === "Soccer" ?
                                    //soccer
                                    (itemBillDetail.lineBetting.includes("Corners")
                                        ?
                                        //lấy tỉ số phạt góc
                                        `HT: ${Number(itemBillDetail.billDetailResult.homeCornersQ1)}:${Number(itemBillDetail.billDetailResult.awayCornersQ1)} / FT ${Number(itemBillDetail.billDetailResult.homeCornersQ2)}:${Number(itemBillDetail.billDetailResult.awayCornersQ2)}`
                                        :
                                        //lấy tỉ số trận đấu
                                        `HT: ${Number(itemBillDetail.billDetailResult.homeQ1)}:${Number(itemBillDetail.billDetailResult.awayQ1)} / FT: ${Number(itemBillDetail.billDetailResult.homeQ2)}:${Number(itemBillDetail.billDetailResult.awayQ2)}`
                                    )
                                    :
                                    (
                                        itemBillDetail.sport === "NBA" || itemBillDetail.sport === "NFL"
                                            ?
                                            // trường hợp NBA và NFL là tổng 4 hiệp
                                            `HT: 
                                        ${Number(itemBillDetail.billDetailResult.homeQ1) +
                                            Number(itemBillDetail.billDetailResult.homeQ2)}
                                            :
                                            ${Number(itemBillDetail.billDetailResult.awayQ1) +
                                            Number(itemBillDetail.billDetailResult.awayQ2)
                                            }
                                         / FT: 
                                         ${Number(itemBillDetail.billDetailResult.homeQ1) +
                                            Number(itemBillDetail.billDetailResult.homeQ2) +
                                            Number(itemBillDetail.billDetailResult.homeQ3) +
                                            Number(itemBillDetail.billDetailResult.homeQ4)}
                                        :${Number(itemBillDetail.billDetailResult.awayQ1) +
                                            Number(itemBillDetail.billDetailResult.awayQ2) +
                                            Number(itemBillDetail.billDetailResult.awayQ3) +
                                            Number(itemBillDetail.billDetailResult.awayQ4)
                                            }`
                                            :
                                            // trường hợp MLB thì tổng là tất cả các hiệp
                                            `HT: 
                                        ${Number(itemBillDetail.billDetailResult.homeQ1) +
                                            Number(itemBillDetail.billDetailResult.homeQ2) +
                                            Number(itemBillDetail.billDetailResult.homeQ3) +
                                            Number(itemBillDetail.billDetailResult.homeQ4) +
                                            Number(itemBillDetail.billDetailResult.homeQ5)
                                            }
                                        :
                                        ${Number(itemBillDetail.billDetailResult.awayQ1) +
                                            Number(itemBillDetail.billDetailResult.awayQ2) +
                                            Number(itemBillDetail.billDetailResult.awayQ3) +
                                            Number(itemBillDetail.billDetailResult.awayQ4) +
                                            Number(itemBillDetail.billDetailResult.awayQ5)
                                            }
                                         / FT: 
                                         ${Number(itemBillDetail.billDetailResult.homeQ1) +
                                            Number(itemBillDetail.billDetailResult.homeQ2) +
                                            Number(itemBillDetail.billDetailResult.homeQ3) +
                                            Number(itemBillDetail.billDetailResult.homeQ4) +
                                            Number(itemBillDetail.billDetailResult.homeQ5) +
                                            Number(itemBillDetail.billDetailResult.homeQ6) +
                                            Number(itemBillDetail.billDetailResult.homeQ7) +
                                            Number(itemBillDetail.billDetailResult.homeQ8) +
                                            Number(itemBillDetail.billDetailResult.homeQ9) +
                                            Number(itemBillDetail.billDetailResult.homeOt)
                                            }
                                        :
                                        ${Number(itemBillDetail.billDetailResult.awayQ1) +
                                            Number(itemBillDetail.billDetailResult.awayQ2) +
                                            Number(itemBillDetail.billDetailResult.awayQ3) +
                                            Number(itemBillDetail.billDetailResult.awayQ4) +
                                            Number(itemBillDetail.billDetailResult.awayQ5) +
                                            Number(itemBillDetail.billDetailResult.awayQ6) +
                                            Number(itemBillDetail.billDetailResult.awayQ7) +
                                            Number(itemBillDetail.billDetailResult.awayQ8) +
                                            Number(itemBillDetail.billDetailResult.awayQ9) +
                                            Number(itemBillDetail.billDetailResult.awayOt)
                                            }`

                                    )
                                }
                            </span>
                        </div>
                        <div className="flex items-center justify-between">
                            <span className="text-sm font-bold uppercase text-[#E4E7F1]">odds</span>
                            <span className="text-sm text-[#E4E7F1]">{itemBillDetail.score.split(' ').length > 1 ? itemBillDetail.score.split(' ')[1] : itemBillDetail.score.split(' ')[0]}</span>
                        </div>
                    </>
                ))}




                <div className="flex items-center justify-between">
                    <span className="text-sm font-bold uppercase text-[#E4E7F1]">stake</span>
                    <span className="text-sm text-[#D4B962]">${item.totalStake}</span>
                </div>
                <div className="flex items-center justify-between">
                    <span className="text-sm font-bold uppercase text-[#E4E7F1]">Win/Lose</span>
                    <span className={`text-sm text-[#33E49A] ${(item.result === 1 && 'text-[#33E49A]') || (item.result === 2 && ' text-[#FF4A59]') || (item.result === 3 && ' text-[#666E97]') || (item.result === 0 && 'text-[#D4B962]')}`}>
                        ${item.winLose ? item.winLose : 0}
                    </span>
                </div>
                <div className="flex items-center justify-between">
                    <span className="text-sm font-bold uppercase text-[#E4E7F1]">Status</span>
                    <span className={`text-sm text-[#33E49A] ${(item.result === 1 && 'text-[#33E49A]') || (item.result === 2 && ' text-[#FF4A59]') || (item.result === 3 && ' text-[#666E97]') || (item.result === 0 && 'text-[#D4B962]')}`}>
                        {(item.result === 1 && (item.isHalf ? 'WIN 1/2' : "WIN")) || (item.result === 2 && (item.isHalf ? ' LOSE  1/2' : " LOSE ")) || (item.result === 3 && 'CANCEL') || (item.result === 0 && 'DRAW')}
                    </span>
                </div>

            </div>
        </div>

    )
}