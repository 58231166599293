import React, { useEffect, useState } from 'react';
// import { Banner1, Banner2, Banner3, Banner4 } from '../../../../components/imgExport';
import * as homePageApi from '../../../../api/homePage/homePageApi';
import { URL_BANNER } from '../../../../env';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
interface Props {
    sportId?: number,
}
const Banner_V2: React.FC<Props> = ({
    sportId
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [value] = React.useState<string>('horizontal');
    const [banner, setBanner] = useState<homePageApi.infoBanner[]>([])
    const getBanner = async () => {
        const ketqua = await homePageApi.getBanners(sportId ? sportId : undefined)
        if (ketqua?.success) {
            setBanner(ketqua.data)
        } else {
            setBanner([])
        }
    }
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        arrows: false,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    }
    useEffect(() => {
        getBanner()
    }, [])
    return (
        <div className='slider-container xs:w-full  xl:w-[755px] xl2:!w-[780px] xl3:!w-[1131px] xs:pt-[70px] xl:pt-7 xs:pb-0 xl:pb-[15px]  bg-[#131620] xs:pl-0 xl:pl-2'>
            <Slider {...settings} >
                {
                    banner && banner.map((item, i) => (
                        <div className='mr-[5px] ml-[5px] xs:w-full   xl:!w-[170px] xl2:!w-[185px] xl3:!w-[260px]' key={i}>
                            <img className='xs:w-full  xl:!w-[170px] xl2:!w-[185px] xl3:!w-[252px] h-[130px]' src={URL_BANNER + item.image} alt={item.image} />
                        </div>
                    ))
                }
            </Slider>
        </div>
    );
};

export default Banner_V2;