import { Collapse, DatePicker } from "antd";
import { Collapse_img, Dollar, Expand, TransactionWhite, calendar, empty } from "../../../components/imgExport"
import type { Dayjs } from 'dayjs';
import type { TimeRangePickerProps } from 'antd';
import dayjs from "dayjs";
import './style.css'
import { HeaderItem } from "../Item/HeaderItem";
import { ItemSoccerSettled } from "../../Mybet/Settled/Item/ItemSoccerSettled";
import { convertTimeZone } from "../../../DateTime";
import { getPresentMoney } from "../../../api/billAcceptor/billAcceptorApi";
import { useDispatch, useSelector } from 'react-redux';
import { selectorUser, setUserMoney } from "../../../store/slices/userSlice";
import { formatPrice } from "../../../until";
const data = [
    { id: 1 }
]
const { RangePicker } = DatePicker;
interface ItemProp {
    money?: number
    listBill?: any[]
    startDate?: string
    endDate?: string
    setStartDate?: (value: string) => void
    setEndDate?: (value: string) => void
    time?: number
    getCurrentMoney?: () => void
}

export const SoccerStatement: React.FC<ItemProp> = ({
    money,
    listBill,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    time,
    getCurrentMoney
}) => {
    const { presentMoney } = useSelector(selectorUser);
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            // console.log('From: ', dates[0], ', to: ', dates[1]);
            // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            setStartDate && setStartDate(dateStrings[0])
            setEndDate && setEndDate(dateStrings[1])
        } else {
            console.log('Clear');
        }
    };
    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'This day', value: [dayjs('presets'), dayjs()] },
        { label: 'This week', value: [dayjs().startOf('week'), dayjs()] },
        { label: 'Last week', value: [dayjs().startOf('week').add(-7, 'd'), dayjs().endOf('week').add(-7, 'd')] },
        { label: 'Past 7 Day', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'This month', value: [dayjs().startOf('month'), dayjs()] },
        { label: 'Last month', value: [dayjs().startOf('month').add(-30, 'd'), (dayjs().startOf('month').add(-30, 'd')).endOf('month')] },
    ];

    return (
        <div className={`  w-full border-r border-r-[#202530] border-l border-l-[#202530] border-b border-b-[#202530] `}>
            <div className={` xl:h-[calc(100vh-315px)] ${data.length === 0 ? 'xs:h-[calc(100vh-350px)]' : 'xs:h-[calc(100vh-400px)]  '}`}>
                {
                    data.length > 0
                        ? (
                            <div className="flex flex-col gap-6 xs:h-[calc(100vh-420px)] xl:h-[calc(100vh-235px)]">
                                <div className="xs:hidden md:flex items-center justify-between px-6">
                                    <div className="flex items-center rounded-lg border border-[#4B5563] px-4 py-3">
                                        <img src={calendar} alt="" />
                                        <RangePicker
                                            presets={rangePresets}
                                            onChange={onRangeChange}
                                            size="small"
                                            style={{ width: '325px' }}
                                            suffixIcon={false}
                                            defaultValue={[dayjs().startOf('week'), dayjs()]}
                                            value={[dayjs(startDate), dayjs(endDate)]}
                                            variant="borderless"
                                            className="custom-picker"
                                            popupClassName="custom-popup-picker"
                                            renderExtraFooter={() => <div className="flex w-full gap-4 pb-4">
                                                <button className="w-full bg-[#131620] rounded-lg border border-[#C1C9E1] text-[#C1C9E1] text-xs font-medium py-2">
                                                    Cancel
                                                </button>
                                                <button className="w-full bg-[#D4B962] rounded-lg   text-[#FFFFFF] text-xs font-medium py-2">
                                                    OK
                                                </button>
                                            </div>
                                            }
                                        />
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <img src={Dollar} alt="" />
                                        <span className="font-bold text-sm text-[#E4E7F1] uppercase">opening Balance</span>
                                        <span className="font-bold text-sm text-[#C59D0F] uppercase">${formatPrice(presentMoney || 0)}</span>
                                        <div className='text-sm pl-1 cursor-pointer text-white flex items-center gap-2' onClick={() => getCurrentMoney && getCurrentMoney()}>
                                            {time && time > 0 ? time : <img src={TransactionWhite} alt="Transaction" className='h-[12px] aspect-square' />}
                                            <span className='text-sm fw-[400] uppercase text-white h-fit'>Reload</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-[18px] h-[calc(100vh-300px)] overflow-y-scroll">
                                    {(listBill && listBill.length > 0 && listBill.map((item: any, index: number) => (
                                        <Collapse
                                            className='w-colappse collapse-league-V2'
                                            expandIconPosition='end'
                                            expandIcon={
                                                (expanded) => (
                                                    expanded.isActive ? (
                                                        <div className='w-full flex justify-center gap-2 '>
                                                            <span className="xs:hidden md:block uppercase text-base fw-[400] !text-[#E4E7F1]">Collapse</span>
                                                            <img src={Collapse_img} alt="" />
                                                        </div>

                                                    ) : (
                                                        <div className='w-full flex justify-center gap-2 '>
                                                            <span className="xs:hidden md:block uppercase text-base fw-[400] !text-[#E4E7F1]">expand</span>
                                                            <img src={Expand} alt="" />
                                                        </div>
                                                    )
                                                )
                                            }
                                            items={[{
                                                label: <div className="flex flex-col gap-[10px]">
                                                    <div className='text-[#D4B962] fw-[500] text-base flex items-center uppercase'>
                                                        <div className='h-[21px] bg-[#42391A] rounded-tl-md rounded-bl-md w-[10px]'>
                                                        </div>
                                                        {convertTimeZone(item.fromDate.toString(), "America/Chicago", timeZone).slice(0, 10)}
                                                        {/* {`${item.fromDate} - ${item.endDate}`} */}
                                                    </div>
                                                    <div className="xs:hidden md:flex items-center justify-between">
                                                        <span className="text-sm !text-[#E4E7F1]">Total Win/Lose
                                                            <span className={`text-sm pl-2 ${Number(item.winlose) >= 0 ? "!text-[#33E49A]" : "!text-[#FF4A59]"}`}>{Number(item.winlose) >= 0 ? `$${Number(item.winlose)}` : `$${Math.abs(Number(item.winlose))}`}</span>
                                                        </span>
                                                        <span className="text-sm !text-[#E4E7F1]">Commision
                                                            <span className={`text-sm pl-2 ${Number(item.comission) >= 0 ? "!text-[#33E49A]" : "!text-[#FF4A59]"}`}>{Number(item.comission) >= 0 ? `$${Number(item.comission)}` : `$${Math.abs(Number(item.comission))}`}</span>
                                                        </span>
                                                        <span className="text-sm !text-[#E4E7F1]">Running Total
                                                            <span className={`text-sm pl-2 ${Number(item.runningTotal) >= 0 ? "!text-[#33E49A]" : "!text-[#FF4A59]"}`}>{Number(item.runningTotal) >= 0 ? `$${Number(item.runningTotal)}` : `$${Math.abs(Number(item.runningTotal))}`}</span>
                                                        </span>
                                                    </div>
                                                    <div className="xs:flex md:hidden flex-col gap-1">
                                                        <div className="flex items-center justify-between">
                                                            <span className="text-sm !text-[#E4E7F1] uppercase">Total Win/Lose </span>
                                                            <span className={`text-sm pl-2 ${Number(item.winlose) >= 0 ? "!text-[#33E49A]" : "!text-[#FF4A59]"}`}>{Number(item.winlose) >= 0 ? `$${Number(item.winlose)}` : `$${Math.abs(Number(item.winlose))}`}</span>
                                                        </div>
                                                        <div className="flex items-center justify-between">
                                                            <span className="text-sm !text-[#E4E7F1] uppercase">Commision </span>
                                                            <span className={`text-sm pl-2 ${Number(item.comission) >= 0 ? "!text-[#33E49A]" : "!text-[#FF4A59]"}`}>{Number(item.comission) >= 0 ? `$${Number(item.comission)}` : `$${Math.abs(Number(item.comission))}`}</span>
                                                        </div>
                                                        <div className="flex items-center justify-between">
                                                            <span className="text-sm !text-[#E4E7F1] uppercase">Running Total </span>
                                                            <span className={`text-sm pl-2 ${Number(item.runningTotal) >= 0 ? "!text-[#33E49A]" : "!text-[#FF4A59]"}`}>{Number(item.runningTotal) >= 0 ? `$${Number(item.runningTotal)}` : `$${Math.abs(Number(item.runningTotal))}`}</span>
                                                        </div>
                                                    </div>
                                                </div>,

                                                children:
                                                    <div className="flex flex-col">
                                                        <HeaderItem />
                                                        <div className="flex flex-col xs:gap-[18px] md:gap-0 w-full xs:h-[calc(100vh-350px)] md:h-[calc(100vh-385px)] xl:h-[calc(100vh-350px)] overflow-y-scroll">
                                                            {item.listBill && item.listBill.length && item.listBill.map((item: any) => (
                                                                <ItemSoccerSettled key={item.id} status={1} item={item} />
                                                            ))}
                                                        </div>
                                                    </div>
                                            }]}
                                        />
                                    )))}

                                </div>

                            </div>
                        )
                        : (
                            <div className="flex flex-col">
                                <div className="pt-[120px] flex flex-col items-center gap-3">
                                    <img src={empty} alt="" className="w-[125px] h-[125px] " />
                                    <span className='text-base fw-[400] text-[#C1C9E1]'>You haven't placed any bets yet</span>
                                </div>
                            </div>
                        )
                }
            </div>

        </div>
    )
}