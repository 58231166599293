/* eslint-disable react-hooks/exhaustive-deps */
import HeadNavi from "../homepage/components/MainNavi/Head/HeadNavi";
import { useEffect, useState } from "react";
import { itemMenu } from "../homepage/components/MainNavi/Tabs/TabsNaviV2";
import './style.css'
import { Running } from "./Running";
import { MyBet } from "./MyBet";
import { Settled } from "./Settled";
import { Layout } from "../../routes/Layout";
import useLoading from '../../hook/useLoading';
import useToast from '../../hook/useToast';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert } from '../../store/slices/toastSlice';
import * as myBetApi from '../../api/myBet/myBetApi';
import { TransactionWhite } from "../../components/imgExport";
import useThrottle from "../../hook/useThrottle";
import { getPresentMoney } from "../../api/billAcceptor/billAcceptorApi";
import { selectorUser, setUserMoney } from "../../store/slices/userSlice";
import { formatPrice } from "../../until";
export const Mybet: React.FC = () => {
    const { presentMoney } = useSelector(selectorUser);
    const pushToast = useToast();
    const pushLoading = useLoading();
    const dispatch = useDispatch();
    const [tournaments, setTournaments] = useState(itemMenu[0])
    const [chooseTabMyBet, setChooseTabMyBet] = useState<number>(2)
    const [totalRunning, setTotalRunning] = useState<number>(0)
    const [totalSettled, setTotalSettled] = useState<number>(0)



    const [chooseGame, setChooseGame] = useState<string>('Soccer')
    const [limit, setLimit] = useState<number>(20)
    const [offset, setOffset] = useState<number>(0)
    const [listBill, setListBill] = useState<any>([])
    const [offsetSettled, setOffsetSettled] = useState<number>(0)
    const [listBillSettled, setListBillSettled] = useState<any>([])

    const [money, setMoney] = useState("")
    const [time, setTime] = useState(0)

    const handelChooseTournaments = (value: itemMenu) => {
        const listParent = document.querySelectorAll(".ant-menu-parent-tournament.ant-menu-submenu-selected")
        if (listParent.length > 0) {
            listParent[0].classList.remove("ant-menu-submenu-selected")
        }

        const listChildren = document.querySelectorAll(".ant-menu-children-tournament.ant-menu-item-selected")
        if (listChildren.length > 0) {
            listChildren[0].classList.remove("ant-menu-item-selected")
        }

        setTournaments(value)
        if (value.id !== 9) {
            setTournaments(value)
        }
        if (value.id === 0) {
        }
        window.location.href = value.href
    }

    const getMyBetRunning = async () => {
        pushLoading(true)
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const ketQua = await myBetApi.myBetRunning(chooseGame, timeZone, limit, offset)
        if (ketQua?.success) {
            setTotalRunning(ketQua.total ? ketQua.total : 0)
            setListBill(ketQua.data)
        } else {
            setTotalRunning(0)
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        // 
        pushLoading(false)
    }

    const getMyBetSettled = async () => {
        pushLoading(true)
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const ketQua = await myBetApi.myBetSettled(chooseGame, timeZone, limit, offsetSettled)
        if (ketQua?.success) {
            setTotalSettled(ketQua.total ? ketQua.total : 0)
            setListBillSettled(ketQua.data)
        } else {
            setTotalSettled(0)
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        // 
        pushLoading(false)
    }


    const getCurrentMoney = useThrottle(async () => {
        pushLoading(true);
        var timeTotal = 10
        const interval = setInterval(() => {
            if (timeTotal > 0) {
                timeTotal -= 1
                setTime(timeTotal)
            } else {
                clearInterval(interval)
            }
        }, 1000);
        const ketQua = await getPresentMoney();
        if (ketQua?.success) {
            dispatch(setUserMoney(ketQua.data.currentMoney))
            setMoney(ketQua.data.currentMoney)
        }
        setTimeout(() => {
            pushLoading(false);
        }, 1000)
    }, 10000)


    useEffect(() => {
        getMyBetSettled()
        getMyBetRunning()
    }, [chooseGame])

    useEffect(() => {
        getMyBetRunning()
    }, [offset])

    useEffect(() => {
        getMyBetSettled()
    }, [setOffsetSettled])
    return (
        <Layout>
            <div className='bg-[#131620]  overflow-hidden xs:h-[calc(100vh-120px)] xl:!h-[100vh] w-full'>
                <HeadNavi
                    tournaments={tournaments}
                    setTournaments={handelChooseTournaments}
                />
                <div className="w-full h-full flex justify-center items-center xs:mt-[50px] xl:mt-0">
                    <div className="xs:w-full xl:w-[1200px]  xl3:w-[1375px] xs:ml-0 xl:ml-[100px] xl3:ml-0 flex h-full ">
                        <div className=" flex flex-col items-center justify-center w-full xs:px-0 md:px-5">
                            <div className="lg:flex-col xl:flex-col xl2:flex xl3:flex xl2:flex-row xl3:flex-row justify-between items-center w-full bg-gradient-A98F34 px-6 border-r border-r-[#202530] border-l border-l-[#202530] border-t border-t-[#202530]">
                                <div className="flex">
                                    <div className="border-r border-r-[#5D5846] flex justify-center"
                                    >
                                        <span className={`text-[18px] ] font-medium py-2 xs:pr-6 md:pr-12 whitespace-nowrap ${chooseTabMyBet === 1 ? "text-[#D4B962]" : "text-[#E4E7F1]"}`}>
                                            MY BET
                                        </span>
                                    </div>
                                    <div className="border-r border-r-[#5D5846] flex justify-center items-center gap-[10px] xs:px-6 md:px-12"
                                        onClick={() => setChooseTabMyBet(2)}
                                    >
                                        <span className={`text-[18px] ] font-medium py-2 whitespace-nowrap ${chooseTabMyBet === 2 ? "text-[#D4B962]" : "text-[#E4E7F1]"}`}>
                                            Running
                                        </span>
                                        <div className={`flex justify-center items-center w-5 h-5 rounded-full  text-xs  font-medium ${chooseTabMyBet === 2 ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                            {totalRunning}
                                        </div>
                                    </div>
                                    <div className=" flex justify-center items-center gap-[10px] xs:pl-6 md:pl-12"
                                        onClick={() => setChooseTabMyBet(3)}
                                    >
                                        <span className={`text-[18px] ] font-medium py-2 whitespace-nowrap ${chooseTabMyBet === 3 ? "text-[#D4B962]" : "text-[#E4E7F1]"}`}>
                                            Settled
                                        </span>
                                        <div className={`flex justify-center items-center w-5 h-5 rounded-full  text-xs  font-medium ${chooseTabMyBet === 3 ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                            {totalSettled}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center gap-[10px]"
                                >
                                    <span className={`text-sm font-medium py-2 whitespace-nowrap ${chooseTabMyBet === 3 ? "text-[#D4B962]" : "text-[#E4E7F1]"}`}>
                                        Open Balance
                                    </span>
                                    <div className="flex items-center gap-2">
                                        <span className="font-bold text-sm text-[#C59D0F] uppercase">${formatPrice(presentMoney || 0)}</span>
                                        <div className='text-sm pl-1 cursor-pointer h-10 text-white flex items-center gap-2' onClick={() => getCurrentMoney()}>
                                            {time && time > 0 ? time : <img src={TransactionWhite} alt="Transaction" className='h-[20px] aspect-square' />}
                                            <span className='text-sm fw-[400] uppercase text-white h-fit'>Reload</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                chooseTabMyBet === 1 && (<MyBet />)
                            }
                            {
                                chooseTabMyBet === 2 && (<Running totalRunning={totalRunning} setTotalRunning={(e) => setTotalRunning(e)} listBill={listBill} chooseGame={chooseGame} setLimit={(e) => setLimit(e)} setOffset={(e) => setOffset(e)} setChooseGame={(e) => setChooseGame(e)} />)
                            }
                            {
                                chooseTabMyBet === 3 && (<Settled totalSettled={totalSettled} setTotalSettled={(e) => setTotalSettled(e)} listBill={listBillSettled} chooseGame={chooseGame} setLimit={(e) => setLimit(e)} setOffset={(e) => setOffset(e)} setChooseGame={(e) => setChooseGame(e)} />)
                            }
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    )
}