import React, { useState } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { logoKB_2, menu_open, search, start, user_circle } from '../../../../../components/imgExport';
import '../../stylesMainNavi.css'
import { DrawerLeftMenu } from './Drawer/DrawerLeftMenu';
import { InfoAddStrightSoccerTmp } from '../../../../../api/betting/bettingApi';


interface Props {
    handleOpenDrawerBestSlip?: (value: boolean) => void
    keyBetTabs: string
    listAddStrightBetsSlip?: InfoAddStrightSoccerTmp[]
    openDrawer?: boolean
    closeDrawer?: (value: boolean) => void
}

const onChange = (key: string) => {
    console.log(key);
};

const items: TabsProps['items'] = [
    {
        key: '1',
        label: 'Sports',
    },
    {
        key: '2',
        label: 'Live in Game',
    },
];

const tabMargin = 30;
const tabBarGutter = (items.length - 1) * tabMargin;

const HeadTabs_V2: React.FC<Props> = ({
    listAddStrightBetsSlip, keyBetTabs,
    handleOpenDrawerBestSlip,
}) => {
    const [openDrawerMenu, setOpenDrawerMenu] = useState(false)
    return (
        <div className="xs:relative xs:px-6 xl:px-0 xs:flex xs:justify-between xl:flex xl:items-center xl:justify-center  py-6 border-b border-b-[#202531] bg-[#131620]">
            <DrawerLeftMenu
                openDrawer={openDrawerMenu}
                closeDrawer={() => setOpenDrawerMenu(false)}
            />
            <div className=' xs:gap-3 lg:gap-0 xs:flex xs:items-center'>
                <img src={menu_open} alt="" className='xs:block xl:hidden' onClick={() => setOpenDrawerMenu(true)} />

                <img src={logoKB_2} alt="Logo" className='xs:w-[48px] xs:h-[48px] xl:w-[80px] xl:h-[43px]' onClick={() => { window.location.reload() }} />
            </div>
            <div className="xs:flex xs:items-center xs:gap-3 xl:hidden">
                <div className='flex items-center gap-[10px]' onClick={() => handleOpenDrawerBestSlip && handleOpenDrawerBestSlip(true)}>
                    <img src={start} alt="Crown" className='w-[32px] h-[32px]' />
                    <p className="fw-[700] text-sm text-white uppercase">Bet Slip</p>

                    <span className={`h-[20px] w-[20px] rounded-[50%]   text-xs flex items-center justify-center ${listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 ? 'bg-[#202531] text-white' : 'bg-[#D4B962] text-[#131620]'}`}>
                        {keyBetTabs === '1' ? listAddStrightBetsSlip && listAddStrightBetsSlip.length.toString() : listAddStrightBetsSlip && listAddStrightBetsSlip.length.toString()}
                    </span>
                </div>
                <img src={user_circle} alt="" />
            </div>
            <div className='hidden w-[173px] mr-[28px] ml-[28px]'>

                <Tabs
                    className="headTab"
                    defaultActiveKey="1"
                    items={items}
                    onChange={onChange}
                    tabBarGutter={tabBarGutter}
                />
                {/*  */}
            </div>
            <div className='hidden'>
                <img src={search} alt="Search" />
            </div>

        </div>
    )
}




export default HeadTabs_V2;
