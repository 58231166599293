import { Drawer } from "antd"
import BookmarkIcon from '@mui/icons-material/Bookmark';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { logoKB_2, logout, user_setting } from "../../../../../../components/imgExport";
import './style.css'
import tokenService from '../../../../../../services/token';
import { Link } from "react-router-dom";
interface DrawerProp {
    openDrawer: boolean
    closeDrawer: (value: boolean) => void
}
export const DrawerLeftMenu: React.FC<DrawerProp> = ({
    openDrawer,
    closeDrawer
}) => {
    return (
        <Drawer
            open={openDrawer}
            onClose={() => closeDrawer(false)}
            closeIcon={false}
            width={147}
            placement="left"
            className="!bg-[#131620] p-none-drawer xs:!block xl:!hidden"
            maskClassName="xs:!block xl:!hidden"
        >
            <div className="flex flex-col justify-between h-full">
                <div className="flex flex-col bg-[#131620] w-full">
                    <div className='border-b border-b-[#202531] h-[96px] flex justify-center items-center'>
                        <img src={logoKB_2} alt="Logo" className='w-[50px] h-[50px] ' />
                    </div>
                    <Link to={'/mybet'} className='border-b border-b-[#202531] h-[96px] flex  items-center pl-7'>
                        <div className='flex items-center gap-2 '
                        >
                            <BookmarkIcon className='text-[20px] text-[#C1C9E1]' />
                            <span className='text-sm uppercase font-medium  text-[#C1C9E1]'>
                                my bet
                            </span>
                        </div>
                    </Link>
                    <Link to={'/statement'} className='border-b border-b-[#202531] h-[96px] flex  items-center pl-7'>
                        <div className='flex items-center gap-2 '
                        >
                            <NotificationsActiveIcon className='text-[20px] text-[#C1C9E1]' />
                            <span className='text-sm uppercase font-medium  text-[#C1C9E1]'>
                                Statement
                            </span>
                        </div>
                    </Link>
                    <div className='border-b border-b-[#202531] h-[96px] flex  items-center pl-7'>
                        <div className='flex items-center gap-2 '
                        >
                            <img src={user_setting} alt="" />
                            <span className='text-sm uppercase font-medium  text-[#C1C9E1]'>
                                setting
                            </span>
                        </div>
                    </div>
                </div>
                <button className='flex border-t border-t-[#202531] h-[56px]  justify-center items-center gap-3 w-full '>
                    <img src={logout} alt="" />
                    <span className='text-sm font-medium text-[#C1C9E1]'
                        onClick={() => {
                            tokenService.removeToken()
                            window.location.href = "/login";
                        }}>
                        LOGOUT
                    </span>
                </button>
            </div>

        </Drawer>

    )
}