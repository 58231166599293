export const HeaderItem: React.FC = () => {
    return (
        <div className="xs:hidden md:flex w-full bg-header rounded-tl-lg rounded-tr-lg">
            <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[15%]">
                <span className="text-sm text-[#E4E7F1] font-bold ">TIme & ID</span>
            </div>
            <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[35%]">
                <span className="text-sm text-[#E4E7F1] font-bold">Game</span>
            </div>
            <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[10%]">
                <span className="text-sm text-[#E4E7F1] font-bold uppercase">Score</span>
            </div>
            <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[10%]">
                <span className="text-sm text-[#E4E7F1] font-bold uppercase">odds</span>
            </div>
            <div className="flex justify-center py-[7px] w-[10%] border-r border-r-[#282C38]">
                <span className="text-sm text-[#E4E7F1] font-bold uppercase">STAKE</span>
            </div>
            <div className="flex justify-center py-[7px] w-[10%] border-r border-r-[#282C38]">
                <span className="text-sm text-[#E4E7F1] font-bold uppercase">win/LOSE</span>
            </div>
            <div className="flex justify-center py-[7px] w-[10%]">
                <span className="text-sm text-[#E4E7F1] font-bold uppercase">status</span>
            </div>
        </div>
    )
}