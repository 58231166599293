import { empty } from "../../../components/imgExport"


export const MyBet: React.FC = () => {

    return (
        <div className="w-full xs:h-[calc(100vh-280px)] xl:h-[calc(100vh-166px)] border-r border-r-[#202530] border-l border-l-[#202530] border-b border-b-[#202530]">
            <div className="flex flex-col">
                <div className="pt-[120px] flex flex-col items-center gap-3">
                    <img src={empty} alt="" className="w-[125px] h-[125px] " />
                    <span className='text-base fw-[400] text-[#C1C9E1]'>You haven't placed any bets yet</span>
                </div>
            </div>

        </div>
    )
}