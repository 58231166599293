export const ItemMoreBet: React.FC = () => {
    return (
        <div className="flex flex-col gap-3">
            <div className="flex gap-3 w-full">
                <div className="flex flex-col gap-1 w-[25%]">
                    <span className="text-sm text-[#E4E7F1] font-bold uppercase border-b border-b-[#1D212D] py-2">Odd even</span>
                    <div className="grid grid-cols-2 gap-1 ">
                        <div className="py-3 w-full border border-[#202531] flex flex-col rounded-[4px]">
                            <span className="text-xs font-medium text-[#E4E7F1]">Odd</span>
                            <span className="text-xs font-medium text-[#E4E7F1]">1.92</span>
                        </div>
                        <div className="py-3 w-full border border-[#202531] flex flex-col rounded-[4px]">
                            <span className="text-xs font-medium text-[#E4E7F1]">Even</span>
                            <span className="text-xs font-medium text-[#E4E7F1]">1.92</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-1 w-[75%]">
                    <span className="text-sm text-[#E4E7F1] font-bold uppercase border-b border-b-[#1D212D] py-2">TOTAL GOAL</span>
                    <div className="grid grid-cols-4 gap-1 ">
                        <div className="py-3 w-full border border-[#202531] flex flex-col rounded-[4px]">
                            <span className="text-xs font-medium text-[#E4E7F1] whitespace-nowrap">0 ~ 1</span>
                            <span className="text-xs font-medium text-[#E4E7F1]">1.92</span>
                        </div>
                        <div className="py-3 w-full border border-[#202531] flex flex-col rounded-[4px]">
                            <span className="text-xs font-medium text-[#E4E7F1] whitespace-nowrap">2 ~ 3</span>
                            <span className="text-xs font-medium text-[#E4E7F1]">2.16</span>
                        </div>
                        <div className="py-3 w-full border border-[#202531] flex flex-col rounded-[4px]">
                            <span className="text-xs font-medium text-[#E4E7F1] whitespace-nowrap">4 ~ 6</span>
                            <span className="text-xs font-medium text-[#E4E7F1]">7.00</span>
                        </div>
                        <div className="py-3 w-full border border-[#202531] flex flex-col rounded-[4px]">
                            <span className="text-xs font-medium text-[#E4E7F1] whitespace-nowrap">7 ~ Over</span>
                            <span className="text-xs font-medium text-[#E4E7F1]">240.00</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <span className="text-sm text-[#E4E7F1] font-bold uppercase border-b border-b-[#1D212D] py-2 ">First half correct score</span>
                <div className="grid grid-cols-5 gap-1">
                    <div className="py-3 border border-[#202531] flex flex-col rounded-[4px] w-full">
                        <span className="text-xs font-medium text-[#E4E7F1]">0 : 0</span>
                        <span className="text-xs font-medium text-[#E4E7F1]">3.91</span>
                    </div>
                    <div className="py-3 border border-[#202531] flex flex-col rounded-[4px] w-full">
                        <span className="text-xs font-medium text-[#E4E7F1]">0 : 1</span>
                        <span className="text-xs font-medium text-[#E4E7F1]">5.7</span>
                    </div>
                    <div className="py-3 border border-[#202531] flex flex-col rounded-[4px] w-full">
                        <span className="text-xs font-medium text-[#E4E7F1]">2 : 1</span>
                        <span className="text-xs font-medium text-[#E4E7F1]">13.5</span>
                    </div>
                    <div className="py-3 border border-[#202531] flex flex-col rounded-[4px] w-full">
                        <span className="text-xs font-medium text-[#E4E7F1]">2 : 1</span>
                        <span className="text-xs font-medium text-[#E4E7F1]">13.5</span>
                    </div>
                    <div className="py-3 border border-[#202531] flex flex-col rounded-[4px] w-full">
                        <span className="text-xs font-medium text-[#E4E7F1]">OAS</span>
                        <span className="text-xs font-medium text-[#E4E7F1]">225</span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <span className="text-sm text-[#E4E7F1] font-bold uppercase border-b border-b-[#1D212D] py-2 ">correct score</span>
                <div className="grid grid-cols-5 gap-1">
                    <div className="py-3 border border-[#202531] flex flex-col rounded-[4px] w-full">
                        <span className="text-xs font-medium text-[#E4E7F1]">0 : 0</span>
                        <span className="text-xs font-medium text-[#E4E7F1]">3.91</span>
                    </div>
                    <div className="py-3 border border-[#202531] flex flex-col rounded-[4px] w-full">
                        <span className="text-xs font-medium text-[#E4E7F1]">0 : 1</span>
                        <span className="text-xs font-medium text-[#E4E7F1]">5.7</span>
                    </div>
                    <div className="py-3 border border-[#202531] flex flex-col rounded-[4px] w-full">
                        <span className="text-xs font-medium text-[#E4E7F1]">2 : 1</span>
                        <span className="text-xs font-medium text-[#E4E7F1]">13.5</span>
                    </div>
                    <div className="py-3 border border-[#202531] flex flex-col rounded-[4px] w-full">
                        <span className="text-xs font-medium text-[#E4E7F1]">2 : 1</span>
                        <span className="text-xs font-medium text-[#E4E7F1]">13.5</span>
                    </div>
                    <div className="py-3 border border-[#202531] flex flex-col rounded-[4px] w-full">
                        <span className="text-xs font-medium text-[#E4E7F1]">OAS</span>
                        <span className="text-xs font-medium text-[#E4E7F1]">225</span>
                    </div>
                </div>
            </div>
        </div>
    )
}