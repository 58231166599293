import { Form, Input, Modal } from "antd"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { user_edit } from "../../imgExport";
import { useEffect, useState } from "react";
import useLoading from '../../../hook/useLoading';
import useToast from '../../../hook/useToast';
import { useDispatch } from 'react-redux';
import * as loginApi from '../../../api/login/loginApi';
import tokenService from '../../../services/token';
import { clearAlert } from "../../../store/slices/toastSlice";
import ToastGlobal from "../../ToastGlobal";
interface ModalProp {
    isOpen: boolean
    closeModal: (value: boolean) => void
}

export const ModalChangeUserName: React.FC<ModalProp> = ({
    isOpen,
    closeModal
}) => {
    const pushToast = useToast();
    const pushLoading = useLoading();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [newUserName, setNewUserName] = useState('');
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [isComplexityValid, setIsComplexityValid] = useState(false);
    const [isStartLetter, setIsStartLetter] = useState(false);

    const changeUsername = async () => {
        pushLoading(true)
        if (!newUserName) {
            pushToast("Please enter username", "warn");
            pushLoading(false)
            return
        }
        const ketQua = await loginApi.changeUsername(newUserName)
        if (ketQua.success) {
            pushToast(ketQua?.message ? ketQua?.message : "Change password success.", "success");
            setTimeout(() => {
                tokenService.removeToken()
                window.location.href = "/login";
            }, 2000)
        } else {
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        pushLoading(false)
    }

    useEffect(() => {
        setIsLengthValid(newUserName.length >= 6 && newUserName.length <= 15)
        const regex = /^[a-zA-Z0-9]+$/
        setIsComplexityValid(regex.test(newUserName))

        const regex2 = /^[a-zA-Z][a-zA-Z0-9]*$/
        setIsStartLetter(regex2.test(newUserName))
    }, [newUserName])
    return (
        <Modal
            open={isOpen}
            onCancel={() => closeModal(false)}
            closeIcon={false}
            footer={false}
            centered
            className="custome-modal"
        >
            <div className="flex flex-col gap-6 justify-center items-center">
                <div className="flex flex-col items-center gap-1">
                    <span className="text-lg font-medium text-[#E4E7F1]">Change Username</span>
                    <span className="text-sm  text-[#E4E7F1]">Please choose a new username following the guidelines below.</span>
                </div>
                <Form form={form} className="flex flex-col gap-6 w-full">
                    <Form.Item
                        name="username"
                        rules={[{ required: false }]}
                        className="custom-input rounded-lg border-4B5563 !m-0"
                    >
                        <Input
                            prefix={<img src={user_edit} alt="" />}
                            placeholder="New username"
                            size="large"
                            className="!text-[#C1C9E1] placehoder-C1C9E1"
                            value={newUserName}
                            onChange={(e) => setNewUserName(e.target.value)}
                        />
                    </Form.Item>
                    <div className="flex flex-col gap-1 w-full px-[18px]">
                        <div className="flex items-center gap-1">
                            <CheckCircleIcon className='text-xl  text-[#C1C9E1]' />
                            <span className='text-xs text-[#C1C9E1]'>
                                Must be unique and not already taken by another user.
                            </span>
                        </div>
                        <div className="flex items-center gap-1">
                            <CheckCircleIcon className={`text-xl ${isLengthValid ? 'text-[#C1C9E1]' : 'text-[#FF4A59]'}`} />
                            <span className={`text-xs ${isLengthValid ? 'text-[#C1C9E1]' : 'text-[#FF4A59]'}`}>
                                6-15 characters long
                            </span>
                        </div>
                        <div className="flex items-center gap-1">
                            <CheckCircleIcon className={`text-xl ${isComplexityValid ? 'text-[#C1C9E1]' : 'text-[#FF4A59]'}`} />
                            <span className={`text-xs ${isComplexityValid ? 'text-[#C1C9E1]' : 'text-[#FF4A59]'}`}>
                                Only letters (a-z) and numbers (0-9)
                            </span>
                        </div>
                        <div className="flex items-center gap-1">
                            <CheckCircleIcon className={`text-xl ${isStartLetter ? 'text-[#C1C9E1]' : 'text-[#FF4A59]'}`} />
                            <span className={`text-xs ${isStartLetter ? 'text-[#C1C9E1]' : 'text-[#FF4A59]'}`}>
                                Starts with a letter
                            </span>
                        </div>
                        {/*  */}
                    </div>
                </Form>
                <div className="flex flex-col gap-[10px] rounded-[6px] p-4 bg-[#212531] w-full">
                    <div className="flex items-center gap-2">
                        <InfoIcon className="text-xl  text-[#C1C9E1]" />
                        <span className='text-sm text-[#C1C9E1]'>You cannot change your username for 30 days.</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <InfoIcon className="text-xl  text-[#C1C9E1]" />
                        <span className='text-sm text-[#C1C9E1]'>You must use this username to log in again.</span>
                    </div>
                </div>
                <ToastGlobal />
                <div className="flex gap-3 w-full">
                    <button
                        className="uppercase rounded-lg border border-[#E4E7F1] bg-[#141721] text-sm text-[#E4E7F1] py-[14px] w-full font-bold"
                        onClick={() => closeModal(false)}
                    >
                        cancel
                    </button>
                    <button className="uppercase rounded-lg  bg-[#DABE64] text-sm text-[#131620] py-[14px] w-full font-bold" onClick={() => changeUsername()}>
                        update username
                    </button>
                </div>
                {/* <div className="flex items-center gap-[10px]">
                    <Switch defaultChecked className="bg-D4B962-checked" />
                    <span className="text-xs text-[#C1C9E1]">Log out of all devices</span>
                </div> */}
            </div>
        </Modal>
    )
}