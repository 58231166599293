/* eslint-disable react-hooks/exhaustive-deps */
import { soccerActice, soccer, baketballActive, baketball, rugbyActive, rugby, baseballActive, baseball, empty } from "../../../components/imgExport"
import { ItemSoccer } from "./Item/ItemSoccer"
import ToastGlobal from "../../../components/ToastGlobal";
import { Pagination } from "antd";
import './style.css'

interface ItemProp {
    totalRunning?: number
    setTotalRunning?: (value: number) => void
    listBill?: any[]
    chooseGame?: string
    setLimit?: (value: number) => void
    setOffset?: (value: number) => void
    setChooseGame?: (value: string) => void
}

export const Running: React.FC<ItemProp> = ({
    totalRunning,
    setTotalRunning,
    listBill,
    chooseGame,
    setLimit,
    setOffset,
    setChooseGame,
}) => {
    // const pushToast = useToast();
    // const pushLoading = useLoading();
    // const dispatch = useDispatch();
    // const [chooseGame, setChooseGame] = useState<string>('Soccer')
    // const [limit, setLimit] = useState<number>(20)
    // const [offset, setOffset] = useState<number>(0)
    // const [listBill, setListBill] = useState<any>([])
    // const [total, setTotal] = useState<number>(0)
    // const getMyBetRunning = async () => {
    //     pushLoading(true)
    //     var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    //     const ketQua = await myBetApi.myBetRunning(chooseGame, timeZone, limit, offset)
    //     if (ketQua?.success) {
    //         setTotal(ketQua.total ? ketQua.total : 0)
    //         setTotalRunning && setTotalRunning(ketQua.total ? ketQua.total : 0)
    //         setListBill(ketQua.data)
    //     } else {
    //         setTotal(0)
    //         dispatch(clearAlert())
    //         pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
    //         pushLoading(false)
    //         return
    //     }
    //     // 
    //     pushLoading(false)
    // }

    // useEffect(() => {
    //     getMyBetRunning()
    // }, [chooseGame])

    // useEffect(() => {
    //     getMyBetRunning()
    // }, [offset])
    return (
        <div className="w-full">

            <ToastGlobal />
            <div className="w-full flex items-center xs:px-6 md:px-0 xs:justify-between md:justify-center py-6 border-r border-r-[#202530] border-l border-l-[#202530]">
                <div className="flex items-center gap-2 xs:border-0 md:border-r md:border-r-[#212632]"
                    onClick={() => { setChooseGame && setChooseGame('Soccer'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                >
                    <img src={`${chooseGame === 'Soccer' ? soccerActice : soccer}`} alt="" />
                    <span
                        className={`uppercase text-sm xs:pr-0  md:pr-[51px] ${chooseGame === 'Soccer' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                    >
                        soccer
                    </span>
                </div>
                <div className="flex items-center gap-2 xs:border-0 md:border-r md:border-r-[#212632]"
                    onClick={() => { setChooseGame && setChooseGame('NBA'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                >
                    <img src={`${chooseGame === 'NBA' ? baketballActive : baketball}`} alt="" className="xs:pl-0 md:pl-[51px]" />
                    <span className={`uppercase text-sm  xs:pr-0  md:pr-[51px] ${chooseGame === 'NBA' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>nba</span>
                </div>
                <div className="flex items-center gap-2 xs:border-0 md:border-r md:border-r-[#212632]"
                    onClick={() => { setChooseGame && setChooseGame('NFL'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                >
                    <img src={`${chooseGame === 'NFL' ? rugbyActive : rugby}`} alt="" className="xs:pl-0 md:pl-[51px]" />
                    <span className={`uppercase text-sm  xs:pr-0  md:pr-[51px] ${chooseGame === 'NFL' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>nfl</span>
                </div>
                <div className="flex items-center gap-2 "
                    onClick={() => { setChooseGame && setChooseGame('MLB'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                >
                    <img src={`${chooseGame === 'MLB' ? baseballActive : baseball}`} alt="" className="xs:pl-0 md:pl-[51px]" />
                    <span className={`uppercase text-sm   ${chooseGame === 'MLB' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>mlb</span>
                </div>
            </div>
            <div className='w-full border-r border-r-[#202530] border-l border-l-[#202530] border-b border-b-[#202530]'>
                <div className={`xs:px-6 md:px-[48px] xl:h-[calc(100vh-315px)] ${listBill && listBill.length === 0 ? 'xs:h-[calc(100vh-410px)] ' : 'xs:h-full overflow-hidden'}`}>
                    {
                        listBill && listBill.length > 0
                            ? (
                                <div className="flex flex-col">
                                    <div className="xs:hidden md:flex w-full bg-header rounded-tl-lg rounded-tr-lg">
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[15%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold ">TIme & ID</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[40%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold">Game</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[15%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold uppercase">odds</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[15%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold uppercase">stake</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] w-[15%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold uppercase">Status</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col xs:gap-[18px] md:gap-0 w-full xs:h-[calc(100vh-410px)] md:h-[calc(100vh-385px)] xl:h-[calc(100vh-350px)] overflow-y-scroll">
                                        {listBill && listBill.map((item: any) => (
                                            <ItemSoccer key={item.id} status={1} item={item} />
                                        ))}
                                    </div>
                                </div>
                            )
                            : (
                                <div className="flex flex-col">
                                    <div className="pt-[120px] flex flex-col items-center gap-3">
                                        <img src={empty} alt="" className="w-[125px] h-[125px] " />
                                        <span className='text-base fw-[400] text-[#C1C9E1]'>You haven't placed any bets yet</span>
                                    </div>
                                </div>
                            )
                    }
                </div>
                <div className="w-full border border-[#263859] rounded-[4px]">
                    <Pagination align="center" defaultCurrent={1} total={Math.round(totalRunning ? totalRunning / 2 : 1)} className="custom-paginaton" onChange={(e) => setOffset && setOffset((e - 1) * 20)} />
                </div>
            </div>
            {/* {
                chooseGame === 1 && (<RunningSoocer />)
            }
            {
                chooseGame === 2 && (<RunningNBA />)
            }
            {
                chooseGame === 3 && (<RunningNFL />)
            }
            {
                chooseGame === 4 && (<RunningMLB />)
            } */}
        </div>
    )
}